import moment from 'moment';
import { upperFirst, round, sumBy } from 'lodash';
import ApiService from './api.service';

// actions
export const GET_REFUND_REPORT = 'getRefundReport';
export const DOWNLOAD_REFUND = 'downloadRefund';
export const GET_TRANSACTIONS = 'getTransactions';
export const GET_TRANSACTIONS_PRODCUTS = 'getTransactionProducts';
export const DETAIL_TRANSACTION = 'detailTransactions';
export const SUMMARY_TRANSACTION = 'summaryTransaction';
export const GET_FINANCE_TRANSACTION = 'getFinanceTransaction';
export const DOWNLOAD_FINANCE_TRANSACTION = 'downloadFinanceTransaction';
export const DAILY_CHART_TRANSACTION = 'dailyTransaction';
export const VOUCHER_SUMMARY_TRANSACTION = 'summaryVoucherTransaction';
export const MOST_USED_PROMO = 'mostUsedPromoTransaction';
export const PAYMENT_METHOD_LIST = 'paymentMethodList';
export const DOWNLOAD_TRANSACTIONS = 'downloadTransaction';
export const GET_VENDOR_BALANCE_FINANCE = 'getVendorBalanceFinance';
export const RESET_TRANSACTION = 'resetTransactions';

// mutations
export const SET_TRANSACTIONS = 'setTransactions';
export const SET_REFUND_REPORT = 'setRefundReport';
export const SET_TRANSACTIONS_PRODUCTS = 'setTransactionProducts';
export const SET_DETAIL = 'setDetailTransactions';
export const SET_FINANCE_TRANSACTION = 'setFinanceTransactions';
export const SET_PAYMENT_METHOD_LIST = 'setPaymentMethodList';
export const RESET_DETAIL = 'resetDetailTransactions';

const state = {
  refund_report: {},
  transactions: {},
  transaction_products: {},
  trx: {},
  finance_trx: {},
  payment_method_list: [],
  titles: [],
};

const getters = {
  refund_report(state) {
    return state.refund_report;
  },
  transactions(state) {
    return state.transactions;
  },
  transaction_products(state) {
    return state.transaction_products;
  },
  trx(state) {
    return state.trx;
  },
  finance_trx(state) {
    return state.finance_trx;
  },
  titles(state) {
    return state.titles;
  },
  payment_method_list(state) {
    return state.payment_method_list;
  },
};

const actions = {
  [GET_REFUND_REPORT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/transaction/report-refund', param)
        .then(async ({ data }) => {
          await context.commit(SET_REFUND_REPORT, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_REFUND](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/transaction/report-refund', param)
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TRANSACTIONS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/transactions', param)
        .then(async ({ data }) => {
          await context.commit(SET_TRANSACTIONS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_TRANSACTIONS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/transactions', param)
        .then(async (response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_TRANSACTIONS_PRODCUTS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/transactions-product', param)
        .then(async ({ data }) => {
          await context.commit(SET_TRANSACTIONS_PRODUCTS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DETAIL_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/transactions/${param}`)
        .then(async ({ data }) => {
          await context.commit(SET_DETAIL, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SUMMARY_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/transactions-summary', param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_FINANCE_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/finance/transactions', param)
        .then(async ({ data }) => {
          await context.commit(SET_FINANCE_TRANSACTION, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DOWNLOAD_FINANCE_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/finance/transactions/download', param)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [DAILY_CHART_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/transactions-daily', param)
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [VOUCHER_SUMMARY_TRANSACTION](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/transactions-voucher', param)
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [MOST_USED_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/most-used-promo', param)
        .then((data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [PAYMENT_METHOD_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/payment-method')
        .then(async (data) => {
          await context.commit(SET_PAYMENT_METHOD_LIST, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_VENDOR_BALANCE_FINANCE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/finance/transactions/balance/${param.vendor}`)
        .then(async (data) => {
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET_TRANSACTION](context) { // Action untuk mereset transaction
    return new Promise((resolve, reject) => {
      context.commit(RESET_DETAIL); // Memanggil mutation reset
      resolve();
    });
  },
};

const mutations = {
  [SET_REFUND_REPORT](state, data) {
    let rows = [];
    if (data.count) {
      try {
        rows = data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.amount_str = v.total_transaction ? `Rp. ${v.total_transaction.toLocaleString('id-ID')}` : 'Rp. 0';
          v.discount_str = v.discount ? `Rp. ${v.discount.toLocaleString('id-ID')}` : 'Rp. 0';
          v.admin_fee_str = v.admin_fee ? `Rp. ${v.admin_fee.toLocaleString('id-ID')}` : 'Rp. 0';
          v.payment_vendor_str = upperFirst(v.payment_vendor);
          // v.payment_method_str = v.pm.payment_name;
          //del
          v.payment_method_str = v.pm ? v.pm.payment_name : '';

          if (v.detail_discount) {
            v.detail_discount = v.detail_discount.map((vd) => {
              vd.promo_discount_str = vd.promo_discount ? `Rp. ${vd.promo_discount.toLocaleString('id-ID')}` : 'Rp. 0';
              return vd;
            });
          }
          if (v.additional_data) {
            v.additional_data = v.additional_data.map((vd) => {
              vd.additional_fee_str = vd.additional_fee ? `Rp. ${vd.additional_fee.toLocaleString('id-ID')}` : 'Rp. 0';
              return vd;
            });
          }
          if (v.status === 1) {
            v.status_str = 'Waiting Payment';
            v.status_color_str = 'tag-warning';
          } else if (v.status === 2) {
            v.status_str = 'Process';
            v.status_color_str = 'tag-info';
          } else if (v.status === 3) {
            v.status_str = 'Success';
            v.status_color_str = 'tag-success';
          } else if (v.status === 4) {
            v.status_str = 'Expired';
            v.status_color_str = 'tag-danger';
          } else if (v.status === 5) {
            v.status_str = 'Failed';
            v.status_color_str = 'tag-danger';
          } else if (v.status === 6) {
            v.status_str = 'Refund Request';
            v.status_color_str = 'tag-teal';
          } else if (v.status === 7) {
            v.status_str = 'Refund Success';
            v.status_color_str = 'tag-cyan';
          } else if (v.status === 8) {
            v.status_str = 'Refund Rejected';
            v.status_color_str = 'tag-indigo';
          }
          return v;
        });
      } catch (error) {
        console.log(error);
      }
    }
    data.rows = rows;
    state.refund_report = data;
  },
  [SET_TRANSACTIONS](state, data) {
    let rows = [];
    if (data.count) {
      try {
        rows = data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.amount_str = v.total_transaction ? `Rp. ${v.total_transaction.toLocaleString('id-ID')}` : 'Rp. 0';
          v.discount_str = v.discount ? `Rp. ${v.discount.toLocaleString('id-ID')}` : 'Rp. 0';
          v.admin_fee_str = v.admin_fee ? `Rp. ${v.admin_fee.toLocaleString('id-ID')}` : 'Rp. 0';
          v.payment_vendor_str = upperFirst(v.payment_vendor);
          // v.payment_method_str = v.pm.payment_name;
          //del
          v.payment_method_str = v.pm ? v.pm.payment_name : '';

          if (v.detail_discount) {
            v.detail_discount = v.detail_discount.map((vd) => {
              vd.promo_discount_str = vd.promo_discount ? `Rp. ${vd.promo_discount.toLocaleString('id-ID')}` : 'Rp. 0';
              return vd;
            });
          }
          if (v.additional_data) {
            v.additional_data = v.additional_data.map((vd) => {
              vd.additional_fee_str = vd.additional_fee ? `Rp. ${vd.additional_fee.toLocaleString('id-ID')}` : 'Rp. 0';
              return vd;
            });
          }
          if (v.status === 1) {
            v.status_str = 'Waiting Payment';
            v.status_color_str = 'tag-warning';
          } else if (v.status === 2) {
            v.status_str = 'Process';
            v.status_color_str = 'tag-info';
          } else if (v.status === 3) {
            v.status_str = 'Success';
            v.status_color_str = 'tag-success';
          } else if (v.status === 4) {
            v.status_str = 'Expired';
            v.status_color_str = 'tag-danger';
          } else if (v.status === 5) {
            v.status_str = 'Failed';
            v.status_color_str = 'tag-danger';
          } else if (v.status === 6) {
            v.status_str = 'Refund Request';
            v.status_color_str = 'tag-teal';
          } else if (v.status === 7) {
            v.status_str = 'Refund Success';
            v.status_color_str = 'tag-cyan';
          } else if (v.status === 8) {
            v.status_str = 'Refund Rejected';
            v.status_color_str = 'tag-indigo';
          }
          return v;
        });
      } catch (error) {
        console.log(error);
      }
    }
    data.rows = rows;
    state.transactions = data;
  },
  [SET_TRANSACTIONS_PRODUCTS](state, data) {
    state.transaction_products = data;
  },
  [SET_DETAIL](state, data) {
    state.trx = data;
  },
  [SET_FINANCE_TRANSACTION](state, data) {
    try {
      // if (data.count) {
      //   data.rows = data.rows.map((v, index) => {
      //     // const item = v;
      //     const {
      //       detail_product, quantity, sell_price, total_price, v_price, base_price, transaction_qri, amount, voucher,
      //     } = v;
      //     v.source_type_str = upperFirst(v.source_type);
      //     if (v.source_type === 'v_price') {
      //       v.source_type_str = 'PPOB';
      //     } else if (v.source_type === 'special_program') {
      //       v.source_type_str = 'Spesial Program';
      //     } else if (v.source_type === 'lms') {
      //       v.source_type_str = 'Academy';
      //     }
      //     v.product = {};
      //     const mdr_percentage = v.order.pm.payment_vendor.toLowerCase() === 'e2pay' ? 0.01 : 0;

      //     if (v.source_type === 'voucher') {
      //       v.product = {
      //         name: voucher.voucher_name,
      //         base_price: voucher.voucher_base_price,
      //         base_price_str: voucher.voucher_base_price ? `Rp. ${voucher.voucher_base_price.toLocaleString('id-ID')}` : voucher.voucher_base_price,
      //         sell_price,
      //         sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
      //         quantity,
      //         total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
      //         vendor_sell_price: base_price,
      //         vendor_sell_price_str: `Rp. ${base_price.toLocaleString('id-ID')}`,
      //         vendor_total_sell_price: base_price * quantity,
      //         vendor_total_sell_price_str: `Rp. ${(base_price * quantity).toLocaleString('id-ID')}`,
      //         sell_price_promo: sell_price === voucher.voucher_normal_sell_price ? voucher.voucher_normal_sell_price : sell_price,
      //         discount: sell_price === voucher.voucher_normal_sell_price ? 0 : (voucher.voucher_normal_sell_price - sell_price) * quantity,
      //         normal_sell_price: voucher.voucher_normal_sell_price,
      //         normal_sell_price_str: `Rp. ${voucher.voucher_normal_sell_price.toLocaleString('id-ID')}`,
      //       };
      //       // if (voucher.voucher_sku_vendor_id) {
      //       v.product.base_price = base_price;
      //       v.product.base_price_str = `Rp. ${v.product.base_price.toLocaleString('id-ID')}`;
      //       // }
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_normal_sell_price = v.product.normal_sell_price * quantity;
      //       v.product.total_normal_sell_price_str = `Rp. ${v.product.total_normal_sell_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     } else if (v.source_type === 'v_price') {
      //       let vendor_base_price = base_price;
      //       if (v.mobile_transaction) {
      //         vendor_base_price = v.mobile_transaction.price;
      //       }
      //       // const bp = [1, 8].includes(item.product_vendor) ? vendor_base_price : base_price
      //       v.product = {
      //         name: v_price.product_code,
      //         base_price,
      //         base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
      //         sell_price,
      //         sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
      //         quantity,
      //         total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
      //         vendor_sell_price: vendor_base_price,
      //         vendor_sell_price_str: `Rp. ${vendor_base_price.toLocaleString('id-ID')}`,
      //         vendor_total_sell_price: vendor_base_price * quantity,
      //         vendor_total_sell_price_str: `Rp. ${(vendor_base_price * quantity).toLocaleString('id-ID')}`,
      //         sell_price_promo: sell_price,
      //         discount: 0,
      //       };
      //       if (v_price.vendor_id.toString() === '8') {
      //         if (v.detail_product.provider && v.detail_product.nominal) {
      //           v.product.name = [v.detail_product.provider, v.detail_product.nominal].join(' ');
      //         } else {
      //           v.product.name = v_price.nominal;
      //         }
      //       }
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     } else if (v.source_type === 'academic') {
      //       v.product = {
      //         name: detail_product.academic_name,
      //         base_price,
      //         base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
      //         sell_price,
      //         sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
      //         quantity,
      //         sell_price_promo: sell_price,
      //         discount: 0,
      //         total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
      //       };
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     } else if (v.source_type === 'qris' && transaction_qri) {
      //       v.vendor = { vendor_name: transaction_qri.transaction_qris_name };
      //       v.product = {
      //         name: 'Qris Mbayar', // transaction_qri.transaction_qris_name,
      //         base_price: 0,
      //         base_price_str: 'Rp. 0',
      //         sell_price: 0,
      //         sell_price_str: 'Rp. 0',
      //         quantity: 1,
      //         sell_price_promo: 0, // this might be change in feature
      //         discount: 0,
      //         total_price: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : '-',
      //         vendor_sell_price: 0,
      //         vendor_sell_price_str: 'Rp. 0',
      //         vendor_total_sell_price: 0,
      //         vendor_total_sell_price_str: 'Rp. 0',
      //         normal_sell_price: amount,
      //         normal_sell_price_str: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : '-',
      //         total_normal_sell_price_str: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : '-',
      //       };
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     } else if (v.source_type === 'special_program') {
      //       v.product = {
      //         name: detail_product.productName,
      //         base_price: 0,
      //         base_price_str: 'Rp. 0',
      //         sell_price: detail_product.price,
      //         sell_price_str: `Rp. ${detail_product.price.toLocaleString('id-ID')}`,
      //         quantity: 1,
      //         total_price: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : '-',
      //         vendor_sell_price: base_price,
      //         vendor_sell_price_str: `Rp. ${base_price.toLocaleString('id-ID')}`,
      //         vendor_total_sell_price: base_price * quantity,
      //         vendor_total_sell_price_str: `Rp. ${(base_price * quantity).toLocaleString('id-ID')}`,
      //         sell_price_promo: sell_price,
      //         discount: 0,
      //       };
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     } else if (v.source_type === 'lms') {
      //       v.product = {
      //         name: detail_product.name,
      //         base_price,
      //         base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
      //         sell_price,
      //         sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
      //         quantity,
      //         total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
      //         vendor_sell_price: base_price,
      //         vendor_sell_price_str: `Rp. ${base_price.toLocaleString('id-ID')}`,
      //         vendor_total_sell_price: base_price * quantity,
      //         vendor_total_sell_price_str: `Rp. ${(base_price * quantity).toLocaleString('id-ID')}`,
      //         sell_price_promo: sell_price,
      //         discount: 0,
      //       };
      //       v.product.total_base_price = v.product.quantity * v.product.base_price;
      //       v.product.total_base_price_str = `Rp. ${v.product.total_base_price.toLocaleString('id-ID')}`;

      //       v.product.total_sell_price = v.product.quantity * v.product.sell_price;
      //       v.product.total_sell_price_str = `Rp. ${v.product.total_sell_price.toLocaleString('id-ID')}`;
      //     }

      //     if (!v.product.normal_sell_price) {
      //       v.product.normal_sell_price = v.product.sell_price;
      //       v.product.normal_sell_price_str = `Rp. ${v.product.normal_sell_price.toLocaleString('id-ID')}`;

      //       v.product.total_normal_sell_price = v.product.sell_price;
      //       v.product.total_normal_sell_price_str = `Rp. ${v.product.total_normal_sell_price.toLocaleString('id-ID')}`;
      //     }

      //     v.product.total_sell_price_promo = v.product.sell_price_promo * quantity;
      //     v.product.total_sell_price_promo_str = `Rp. ${v.product.total_sell_price_promo.toLocaleString('id-ID')}`;
      //     v.product.sell_price_promo_str = `Rp. ${(v.product?.sell_price_promo || 0).toLocaleString('id-ID')}`;
      //     v.product.discount_str = `Rp. ${v.product.discount.toLocaleString('id-ID')}`;

      //     if (index === 0 || (index > 0 && data.rows[index - 1].order.invoice_number !== v.order.invoice_number)) {
      //       if (v.order.pm.payment_vendor === 'doku' && v.order.pm.payment_type.toLowerCase() === 'virtual account') {
      //         v.vendor_admin_fee = v.order.pm.admin_fee;
      //         v.vendor_admin_fee_str = `Rp. ${v.vendor_admin_fee.toLocaleString('id-ID')}`;
      //       } else if (v.order.pm.payment_vendor === 'espay') {
      //         v.vendor_admin_fee = v.order.admin_fee;
      //         v.vendor_admin_fee_str = `Rp. ${v.vendor_admin_fee.toLocaleString('id-ID')}`;
      //       } else {
      //         v.vendor_admin_fee_str = 'Rp. 0';
      //         v.vendor_admin_fee = 0;
      //       }
      //     } else {
      //       v.vendor_admin_fee_str = 'Rp. 0';
      //       v.vendor_admin_fee = 0;
      //     }

      //     v.total_user_paid = v.total_price + v.vendor_admin_fee;

      //     v.discount_amount = v.product.discount;
      //     v.revenue_platform = 0;
      //     v.coin_used = 0;
      //     if (index === 0 || (index > 0 && data.rows[index - 1].order.invoice_number !== v.order.invoice_number)) {
      //       if (v.order.additional_data) {
      //         v.revenue_platform = sumBy(v.order.additional_data, 'additional_fee');
      //         v.total_user_paid += v.revenue_platform;
      //       }

      //       v.coin_used = v.order.coin_used;

      //       if (v.order.discount) {
      //         // const total_item_on_invoice = data.rows.filter((val) => val.order.invoice_number === v.order.invoice_number);
      //         // const d = round(v.order.discount / total_item_on_invoice.length, 2);
      //         const d = v.order.discount;
      //         v.product.total_sell_price_promo -= d;
      //         v.product.sell_price_promo = v.product.total_sell_price_promo / quantity;
      //         v.product.total_sell_price_promo_str = `Rp. ${v.product.total_sell_price_promo.toLocaleString('id-ID')}`;
      //         v.discount_amount += d;
      //         v.total_user_paid -= d;
      //         v.total_user_paid_str = `Rp. ${v.total_user_paid.toLocaleString('id-ID')}`;
      //       }

      //       if (v.order.coin_used) {
      //         v.discount_amount += v.order.coin_used;
      //       }

      //       v.total_user_paid -= v.vendor_admin_fee;
      //       v.admin_fee_str = Number.isNaN(v.order.admin_fee) ? 'Rp. 0' : `Rp. ${v.order.admin_fee.toLocaleString('id-ID')}`;
      //       if (v.order.pm.payment_vendor === 'doku' && v.order.pm.payment_type.toLowerCase() === 'virtual account') {
      //         v.admin_fee_venteny_str = `Rp. ${(v.order.pm.admin_fee - v.order.admin_fee).toLocaleString('id-ID')}`;
      //         v.admin_fee_venteny = v.order.pm.admin_fee - v.order.admin_fee;
      //       } else if (v.order.pm.payment_vendor === 'espay') {
      //         v.admin_fee_venteny = v.order.pm.admin_fee - v.order.admin_fee;
      //         v.admin_fee_venteny_str = `Rp. ${v.admin_fee_venteny.toLocaleString('id-ID')}`;
      //       } else {
      //         v.admin_fee_venteny_str = 'Rp. 0';
      //         v.admin_fee_venteny = 0;
      //       }
      //     } else {
      //       v.admin_fee_str = 'Rp. 0';
      //       v.admin_fee_venteny = 0;
      //       v.admin_fee_venteny_str = 'Rp. 0';
      //     }
      //     v.coin_used_str = `Rp. ${v.coin_used.toLocaleString('id-ID')}`;
      //     v.revenue_platform_str = `Rp. ${v.revenue_platform.toLocaleString('id-ID')}`;
      //     v.discount_amount_str = `Rp. ${v.discount_amount ? v.discount_amount.toLocaleString('id-ID') : 0}`;

      //     v.product.mdr_cost = v.order.pm.payment_vendor === 'e2pay' ? (mdr_percentage * v.total_user_paid) : 0;
      //     if (v.product.mdr_cost > 2000) {
      //       v.product.mdr_cost = 2000;
      //     }
      //     v.product.mdr_cost_str = `Rp. ${v.product.mdr_cost.toLocaleString('id-ID')}`;

      //     v.total_revenue = v.product.total_sell_price + (v.order.admin_fee || 0);
      //     if (v.discount_amount) {
      //       v.total_revenue -= v.discount_amount;
      //     } else {
      //       v.discount_amount_str = 'Rp. 0';
      //     }
      //     v.total_revenue_str = `Rp. ${v.total_revenue.toLocaleString('id-ID')}`;

      //     v.margin = 0;
      //     if (v.product.total_normal_sell_price === v.product.total_sell_price_promo) {
      //       v.margin = v.product.total_normal_sell_price - v.product.total_base_price; // - v.vendor_admin_fee;
      //     } else if (v.product.total_normal_sell_price > v.product.total_sell_price_promo) {
      //       v.margin = v.product.total_sell_price_promo - v.product.total_base_price; // - v.vendor_admin_fee;
      //     }
      //     v.margin += v.revenue_platform;

      //     if (v.order.pm.payment_vendor === 'venteny') {
      //       v.margin = v.total_user_paid - v.product.total_base_price;
      //     }
      //     if (v.source_type === 'qris') {
      //       v.margin = v.amount * 0.097;
      //       v.product.total_sell_price_promo = 0;
      //       v.product.total_sell_price_promo_str = 'Rp. 0';
      //       v.total_user_paid = amount;
      //     }

      //     v.promo_name = '-';
      //     if (v.order.detail_discount) {
      //       const p = v.order.detail_discount[0];
      //       if (p && p.promo_type !== 'gift_venteny') {
      //         v.promo_name = p.promo_name;
      //       } else {
      //         v.product.total_sell_price_promo = 0;
      //         v.product.total_sell_price_promo_str = 'Rp. 0';
      //       }
      //     }

      //     v.margin_str = `Rp.${v.margin.toLocaleString('id-ID')}`;
      //     let margin_percentage = 0;
      //     if (v.source_type === 'qris') margin_percentage = 0.097;
      //     else margin_percentage = round((v.margin / v.product.total_normal_sell_price) * 100, 2);

      //     v.margin_percentage_str = `${margin_percentage}%`;

      //     v.total_user_paid_str = `Rp. ${v.total_user_paid.toLocaleString('id-ID')}`;

      //     const status = [
      //       {
      //         status: 'Failed',
      //       },
      //       {
      //         status: 'Process',
      //       },
      //       {
      //         status: 'Success',
      //       },
      //     ];
      //     v.detail_status_str = status[v.order_detail_status].status;
      //     v.refund_amount_str = `Rp. ${(v.refund_amount || 0).toLocaleString('id-ID')}`;
      //     v.payment_success_str = moment(v.order.payment_success_at).format('DD MMM YYYY, HH:mm');
      //     v.total_price_str = v.total_price ? `Rp. ${v.total_price.toLocaleString('id-ID')}` : '-';
      //     return v;
      //   });
      // } else {
      //   data.rows = [];
      // }

      // Inisialisasi objek baru untuk menyimpan judul
      const titles = [];

      if(data?.rows.length){
        for (const key in data?.rows[0]) {
          titles.push(key);
        }
      }
      state.titles = titles;
      state.finance_trx = data;
    } catch (error) {
      console.log(error);
    }
  },
  [SET_PAYMENT_METHOD_LIST](state, data) {
    state.payment_method_list = data.data.rows;
  },
  [RESET_DETAIL](state) { // Mutation untuk mereset detail
    state.trx = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
