<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} </div>
        <el-button type="text" @click="showSearch = !showSearch"><font-awesome-icon icon="search" /></el-button>
      </div>
      <div class="card-body" v-if="showSearch">
        <div class="flex">
          <div class="w-1/2">


            <el-form ref="form" label-width="120px">
              <el-form-item label="Referral Date">
                <el-date-picker v-model="search.date" type="daterange" size="small" :picker-options="datePickerOptions"
                  class="w-full" range-separator="To" start-placeholder="Start date" end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
            </el-form>


          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search" />
          Search</el-button>
      </div>
      <div class="table-container">
        <div class="table-responsive">
          <table class="table card-table text-nowrap table-vcenter">
            <thead>
              <tr>
                <th width="10%">ID</th>
                <th width="20%">Category Name</th>
                <th width="10%">Owned By</th>
                <th width="15%">Referral ID</th>
                <th width="20%">Created At</th>
                <th width="20%">Updated At</th>
                <th width="20%">Status</th>
                <th width="35%">Claim Status</th>
                <th width="20%">Referral Codes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in list" :key="i">
                <td>{{ item.id }}</td>
                <td>{{ item.referral_code.referral_program.name }}</td>
                <td>{{ item.referral_code.owned_by }}</td>
                <td>{{ item.referral_code_id }}</td>
                <td>{{ item.created_at_str }}</td>
                <td>{{ item.updated_at_str }}</td>
                <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.status_color">{{ item.status_str }}</span></td>
                
                <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.claim_status_color">{{ item.claim_status_str }}</span></td>
                <td>
                  {{ item.referral_code.code }}
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import {  GET_REFERRAL_CLAIMS } from '../../store/modules/app_user';
export default {
  components: { Pagination },
  name: 'Referral',
  metaInfo: {
    title: 'Referral Claims',
  },
  data() {
    return {
      user_id: null,
      showSearch: true,
      emptyStateImage,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: '',
      datePickerOptions: {},
      selected_transaction: {  },
      loadingButton: false,
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
    };
  },
  async mounted() {
    const loader = this.$loading.show();
    this.page = 1;
    this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
    this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
    await this.getList();
    loader.hide();
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  methods: {
    async getList() {
      await this.$store.dispatch(GET_REFERRAL_CLAIMS, {
        page: this.page,
        // sort_by: this.search_referral.sort_by,
        // order_by: this.search_referral.order_by,
        // search: this.search_referral.search,
        // search_value: this.search_referral.search_value
        start_date: this.search.start_date,
        end_date: this.search.end_date,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.get_referral_claims;
      this.total_rows = count || 0;
      this.list = rows;
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
/* del */
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table-responsive {
  overflow-x: auto;
}

.buttonload {
  background-color: #006EA5; /* Green background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 12px 16px; /* Some padding */
  font-size: 16px /* Set a font size */
}

.dialog-footer button:first-child {
  margin-right: 10px;
}
</style>