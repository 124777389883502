import ApiService from './api.service';

// actions
export const GET_LIST_LEGAL = 'getListLegal';
export const CREATE_LEGAL = 'createLegal';
export const UPDATE_STATUS_LEGAL = 'updateStatusLegal';
export const UPDATE_LEGAL = 'updateLegal';

// mutations
export const SET_LIST_LEGAL = 'setListLegal';

const state = {
    legal: {},
};

const getters = {
    legal(state) {
    return state.legal; 
  },
};

const actions = {
    [CREATE_LEGAL](context, param) {
        return new Promise((resolve, reject) => {
            ApiService.post(`api/users-legal/legal`, param.data)
            .then(async ({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },
    [UPDATE_STATUS_LEGAL](context, param) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`api/users-legal/legal/${param.id}`, param.data)
            .then(async ({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    [UPDATE_LEGAL](context, param) {
        return new Promise((resolve, reject) => {
            ApiService.put(`api/users-legal/legal/${param.id}`, param.data)
            .then(async ({ data }) => {
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    },

    [GET_LIST_LEGAL](context, { page, query }) {
      return new Promise((resolve, reject) => {
      ApiService.get(`api/users-legal/legal`, query)
          .then(async ({ data }) => {
          await context.commit(SET_LIST_LEGAL, data.data);
          resolve(data);
          })
          .catch((err) => {
          reject(err);
          });
      });
    },
};

const mutations = {
  [SET_LIST_LEGAL](state, data) {
    state.legal = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};