<template>
  <b-modal size="lg" v-model="show" title="MyBenefit" hide-footer @hide="onHide">
    <b-form @submit.prevent="onSubmit" @reset="onClose" v-loading="loading.company_detail">
      <b-container class="bv-example-row">
        <b-row>
          <b-col>
            <b-form-group label="Package">
              <el-select size="small" class="mb-2 mr-2" :loading="loading.package_list" v-model="v$.form.package_id.$model" placeholder="Select package" @change="handlerSelectPackage">
                <el-option
                  v-for="item in package_list"
                  :key="item.id"
                  :label="item.package_name"
                  :value="item.id">
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.package_id.$invalid" v-if="v$.form.package_id.$errors[0]">
                <span> {{ v$.form.package_id.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Effective Date">
              <el-date-picker v-model="v$.form.mybenefit_start_at.$model"
                :picker-options="datePickerOptions"/>
              <b-form-invalid-feedback :state="!v$.form.mybenefit_start_at.$invalid" v-if="v$.form.mybenefit_start_at.$errors[0]">
                <span> {{ v$.form.mybenefit_start_at.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <div class="w-100"></div>
          <b-col>
            <b-form-group label="Cut Off Date">
              <el-select size="small" class="mb-2 mr-2" v-model="v$.form.payoff_date.$model" placeholder="Select date">
                <el-option
                  v-for="option in options"
                  :value="option"
                  :label="option"
                  :key="option">
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.payoff_date.$invalid" v-if="v$.form.payoff_date.$errors[0]">
                <span> {{ v$.form.payoff_date.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Payroll Date">
              <el-select size="small" class="mb-2 mr-2" v-model="v$.form.payroll_date.$model" placeholder="Select date">
                <el-option
                  v-for="option in options"
                  :value="option"
                  :label="option"
                  :key="option">
                </el-option>
              </el-select>
              <b-form-invalid-feedback :state="!v$.form.payroll_date.$invalid" v-if="v$.form.payroll_date.$errors[0]">
                <span> {{ v$.form.payroll_date.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mb-4">
        <b-row>
            <b-col>
              <b-form-group label="Enable EWA Feature">
                <el-switch
                  style="display: block"
                  v-model="v$.form.is_enabled_ewa.$model"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="handlerChangeEwaLoan($event, 'ewa')"
                  active-text="Yes"
                  inactive-text="No"
                />
                <b-form-invalid-feedback
                  :state="!v$.form.is_enabled_ewa.$invalid"
                  v-if="v$.form.is_enabled_ewa.$errors[0]"
                >
                  <span>{{ v$.form.is_enabled_ewa.$errors[0].$message }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row v-if="v$.form.is_enabled_ewa.$model">
                <b-col>
                  <b-form-group label="Admin Fee EWA">
                    <el-input v-model="formattedAdminFeeEwa" placeholder="Rp.0"/>
                    <b-form-invalid-feedback
                      :state="!v$.form.admin_fee_ewa.$invalid"
                      v-if="v$.form.admin_fee_ewa.$errors[0]"
                    >
                      <span>{{ v$.form.admin_fee_ewa.$errors[0].$message }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-if="v$.form.is_enabled_ewa.$model">
                <b-col>
                  <b-form-group label="Limit EWA">
                    <el-input v-model="formattedEwaReqLimit" placeholder="Rp.0"/>
                    <b-form-invalid-feedback
                      :state="!v$.form.ewa_req_limit.$invalid"
                      v-if="v$.form.ewa_req_limit.$errors[0]"
                    >
                      <span>{{ v$.form.ewa_req_limit.$errors[0].$message }}</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-form-group label="Enable Gajian Duluan">
                <el-switch
                  style="display: block"
                  v-model="v$.form.is_enabled_gajianduluan.$model"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Yes"
                  @change="handlerChangeEwaLoan($event, 'loan')"
                  inactive-text="No"
                  :disabled="!form.loan_tracker_company_id"
                />
                <b-form-invalid-feedback :state="form.loan_tracker_company_id !== null">
                  <span>*Perusahaan tidak terdaftar</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-row>
                <b-col>
                  <b-form-group label="Interest Fee" v-if="form.is_enabled_gajianduluan">
                    <el-input placeholder="0%" disabled v-model="form.interest_fee"/>
                    <!-- <b-form-invalid-feedback :state="!v$.form.interest_fee.$invalid" v-if="v$.form.interest_fee.$errors[0]">
                      <span> {{ v$.form.interest_fee.$errors[0].$message }}</span>
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Admin Fee" v-if="form.is_enabled_gajianduluan">
                    <el-input placeholder="0%" disabled v-model="form.admin_fee"/>
                    <!-- <b-form-invalid-feedback :state="!v$.form.admin_fee.$invalid" v-if="v$.form.admin_fee.$errors[0]">
                      <span> {{ v$.form.admin_fee.$errors[0].$message }}</span>
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col>
              <b-form-group label="Enable Reimburse Feature">
                <el-switch
                  style="display: block"
                  v-model="v$.form.is_enabled_reimburse.$model"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-text="Yes"
                  inactive-text="No"/>
              </b-form-group>
            </b-col>
        </b-row>
        <b-col v-if="v$.form.is_enabled_ewa.$model">
          <hr class="mb-4">
          <b-form-group label="Ewa Tier">
            <b-row class="mb-3 font-weight-bold">
              <!-- <b-col cols="2">Packet Name</b-col> -->
              <b-col cols="2">Package</b-col>
              <b-col cols="3">Ewa Req Limit</b-col>
              <b-col cols="3">Admin Fee</b-col>
              <b-col cols="3">Admin Type</b-col>
              <b-col cols="">Action</b-col>
            </b-row>
            <b-row v-for="(item, index) in DataEwaTier" :key="index" class="mb-3" align-items="center">
              <b-col v-if="item" cols="2">
                <b-input v-model="item.packet" type="number" placeholder="-" @input="item.error = checkDuplicatePackets(index)" />  
                <div v-if="item.error" class="text-danger">{{ item.error }}</div>
              </b-col>
              <b-col cols="3">
                <b-input @paste="handlePaste($event, item, 'ewa_req_limit')" v-model="item.ewa_req_limit" :min="0" type="number" placeholder="-" @keydown="preventNegativeInput"  @input="validateDecimalInput($event.target.value, 'ewa_req_limit')" />
              </b-col>
                <b-col cols="3">
                  <b-input  @paste="handlePaste($event, item, 'admin_fee_ewa')"  v-model="item.admin_fee_ewa" :min="0" type="number" placeholder="-" @keydown="preventNegativeInput"  @input="validateDecimalInput($event.target.value, 'admin_fee_ewa')" />
                </b-col>
              <b-col cols="3">
                <el-select class="mb-2" v-model="item.admin_fee_type" placeholder="Select"  style="width: 100%" >
                  <el-option v-for="option in admin_fee_type_options" :key="option.value" :label="option.label" :value="option.value"></el-option>
                </el-select>
              </b-col>
              <b-col cols="">
                <b-button size="xs" @click="removeEwaTier(index)"> <font-awesome-icon icon="trash" class="text-danger"/>&nbsp;</b-button>
              </b-col>
            </b-row>
          </b-form-group>
          <span v-if="!canAddEwaTier" class="text-danger">
          Please fill in all the fields or Remove the rows!
        </span>
         <br v-if="!canAddEwaTier"><br v-if="!canAddEwaTier">
        <b-col align="left">
          <b-button size="sm" @click="addEwaTier" :disabled="!canAddEwaTier">
            Add Ewa Tier
          </b-button>
        </b-col>
        </b-col>
        <br v-if="!canAddEwaTier"><br v-if="!canAddEwaTier">
      </b-container><br>
      <hr class="mb-4">
      <template v-if="form.package_id && benefit_list[0]">
        <h4>Package Benefit</h4>
        <b-list-group v-for="(item, index) in benefit_list[0].detail_product" :key="index">
          <b-list-group-item><font-awesome-icon :icon="['fas', 'check-circle']" class="text-brand"/>&nbsp;<span v-html="item.product_name"></span></b-list-group-item>
        </b-list-group>
      </template>
    </b-form>
    <div class="d-flex float-right mt-2">
      <el-button :disabled="v$.form.$error || !canAddEwaTier" @click="onSubmit" :loading="loading.assign_package" class="mr-2 ml-2" size="small" type="primary" >Submit Request</el-button>
      <!-- <el-button  @click="onSubmit"  class="mr-2 ml-2" size="small" type="primary">Submit Request</el-button> -->
      <el-button @click="onHide" size="small" class="mr-2">Close</el-button>
    </div>
    <create-package :is-shown="showModalCreatePackage" :modal-type="'create'" @onHide="showModalCreatePackage = false" />
  </b-modal>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import {
  required, requiredIf, numeric,
} from '@vuelidate/validators';
import {
  GET_MYBENEFIT, GET_MYBENEFIT_DETAIL, ASSIGN_PACKAGE,
} from '@/store/modules/mybenefit';
import {
  DETAIL_COMPANIES,
} from '@/store/modules/companies';
import popupErrorMessages from '@/library/popup-error-messages';
import CreatePackage from '../mybenefit/CreatePackage.vue';

export default {
  components: { CreatePackage },
  name: 'MyBenefitModal',
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        package_id: { required },
        mybenefit_start_at: { required },
        is_enabled_ewa: { required },
        is_enabled_reimburse: { required },
        is_enabled_gajianduluan: { required },
        payoff_date: {
          required
        },
        payroll_date: {
          required
        },
        // interest_fee: {
        //   required: requiredIf(this.form.is_enabled_gajianduluan),
        //   numeric,
        // },
        // admin_fee: {
        //   required: requiredIf(this.form.is_enabled_gajianduluan),
        //   numeric,
        // },
        admin_fee_ewa: {
          required: requiredIf(this.form.is_enabled_ewa),
        },
        ewa_req_limit: {
          required: requiredIf(this.form.is_enabled_ewa),
        },
      },
    };
  },
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      packetNames: '',
      packet: '',
      ewaRequestLimit: '',
      adminFeeType: '',
      adminFeeEwa: '',
      DataEwaTier: [],
      show: false,
      loading: {
        package_list: false,
        benefit_detail: false,
        assign_package: false,
        company_detail: false,
      },
      package_list: [],
      form: {
        package_id: '',
        loan_tracker_company_id: '',
        mybenefit_start_at: moment().format('YYYY-MM-DD'),
        is_enabled_ewa: false,
        is_enabled_reimburse: false,
        payoff_date: '',
        payroll_date: '',
        admin_fee: '',
        interest_fee: '',
        admin_fee_ewa: '',
        ewa_req_limit: '',
        is_enabled_gajianduluan: false,
      },
      datePickerOptions: {
      },
      benefit_list: [],
      showModalCreatePackage: false,
      options:[],
      admin_fee_type_options: [
        {
          label: 'Percentage',
          value: 'percentage',
        },
        {
          label: 'Fixed',
          value: 'fixed',
        },
      ],
    };
  },
  methods: {
    checkDuplicatePackets(currentIndex) {
      const currentPacketValue = parseInt(this.DataEwaTier[currentIndex].packet);
      let isDuplicateFound = false;
      let errorMessage = null; 
      this.DataEwaTier.forEach((item, index) => {
        const itemPacketValue = parseInt(item.packet);
        if (index !== currentIndex && itemPacketValue === currentPacketValue && currentPacketValue !== 0) {
          isDuplicateFound = true; 
        }
      });
      if (isDuplicateFound) {
        this.DataEwaTier[currentIndex].packet = '0'; 
        errorMessage = 'Package cannot be the same'; 
      }
      return errorMessage;
    },
    handlePaste(event, item, field) {
      event.preventDefault(); 
      let pasteData = (event.clipboardData || window.clipboardData).getData('text');
      pasteData = pasteData.replace(/-/g, ''); 
      this.validateDecimalInput(pasteData, item, field);
      item[field] = pasteData;
    },
    preventNegativeInput(event) {
      if (event.key === '-') {
        event.preventDefault();
        console.warn('Negative sign input prevented.');
      }
    },
    validateDecimalInput(value, item, field) {
      const cleanedValue = value.replace(/-/g, ''); 
      const decimalRegex = /^[0-9]*\.?[0-9]+$/;
      if (decimalRegex.test(cleanedValue)) {
        item[field] = parseFloat(cleanedValue); 
      } else {
        item[field] = parseFloat(item[field]) || 0;
      }
    },
    addEwaTier() {
      this.DataEwaTier.push({
        packet_name: '',
        ewa_req_limit: null,
        admin_fee_ewa: null,
        admin_fee_type: ''
      });
    },
    removeEwaTier(index) {
      this.DataEwaTier.splice(index, 1);
    },
    onHide() {
      this.$emit('onHide');
    },
    async loadMyBenefitList() {
      this.loading.package_list = true;
      await this.$store.dispatch(GET_MYBENEFIT).then((response) => {
        this.package_list = response.rows;
        this.loadBenefitDetail();
      });
      this.loading.package_list = false;
    },
    async loadBenefitDetail() {
      if (!this.form.package_id) {
        return;
      }
      this.loading.benefit_detail = true;
      await this.$store.dispatch(GET_MYBENEFIT_DETAIL, {
        id: this.form.package_id,
      }).then(() => {
        this.benefit_list = this.$store.getters.benefit_list;
      });
      this.loading.benefit_detail = false;
    },
    async loadCompanyDetail() {
      this.loading.company_detail = true;
      await this.$store.dispatch(DETAIL_COMPANIES, {
        id: this.selectedCompany.company_id,
      }).then(({ data }) => {
        this.form = {
          package_id: data.mybenefit_package_id == 0 ? '' : data.mybenefit_package_id,
          mybenefit_start_at: data.mybenefit_package_start_at ? moment(data.mybenefit_package_start_at).format('YYYY-MM-DD') : '',
          is_enabled_ewa: data.is_enabled_ewa,
          // is_enabled_ewa: data.is_enabled_ewa || data.setting?.admin_fee_ewa ? true : false,
          is_enabled_reimburse: data.is_enabled_reimburse,
          is_enabled_gajianduluan: data.is_enabled_gajianduluan,
          // is_enabled_gajianduluan: data.is_enabled_gajianduluan === true && data.loan_tracker_company_id ? true : false,
          payoff_date: data.setting?.payoff_date,
          payroll_date: data.setting?.payroll_date,
          // payoff_date: data.setting?.payoff_date ? moment(data.setting?.payoff_date).format('DD') : '',
          // payroll_date: data.setting?.payroll_date ? moment(data.setting?.payroll_date).format('DD') : '',
          admin_fee_ewa: data.setting?.admin_fee_ewa,
          ewa_req_limit: data.setting?.ewa_req_limit,
          interest_fee: data.company_loan_rate?.interest_rate,
          admin_fee: data.company_loan_rate?.admin_fee_rate,
          loan_tracker_company_id: data.loan_tracker_company_id
        };

        this.DataEwaTier = data.setting?.ewa_tier ?? [];
        // console.log('EWA Tier are :', this.DataEwaTier);
        this.loadBenefitDetail();
      }).catch(() => {});
      this.loading.company_detail = false;
    },
    onSubmit() {
      this.DataEwaTier
      this.v$.form.$touch();
      if (this.v$.form.$error) return;
      const pkg = this.package_list.find((v) => v.id === this.form.package_id);
      this.$confirm(`Are you sure want to assign ${pkg.package_name} package for this company? Package wil active after approval. And package will active from ${moment(this.form.mybenefit_start_at).format('DD MMM YYYY')}`, 'Confirmation', {
        type: 'info',
        confirmButtonText: 'Yes',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            if (this.form.is_enabled_ewa || this.form.is_enabled_gajianduluan) {
              // this.form.admin_fee_ewa = this.form?.admin_fee_ewa.replace(/,/g, '');
              // this.form.ewa_req_limit = this.form?.ewa_req_limit.replace(/,/g, '') a;
              this.form.setting = {
                payoff_date: this.form.payoff_date,
                payroll_date: this.form.payroll_date,
                admin_fee_ewa: this.form.admin_fee_ewa,
                ewa_req_limit: this.form.ewa_req_limit,
                ewa_tier: this.DataEwaTier && this.DataEwaTier.length > 0
                  ? this.DataEwaTier.map(item => ({
                      // ...item,
                      admin_fee_ewa: parseFloat(item.admin_fee_ewa) || 0,
                      ewa_req_limit: parseInt(item.ewa_req_limit, 10) || 0,
                      packet: parseInt(item.packet, 10) || 0,
                      admin_fee_type: item.admin_fee_type || 0
                    }))
                  : []
              };
            }
            await this.$store.dispatch(ASSIGN_PACKAGE, {
              id: pkg.id,
              data: {
                company_id: this.selectedCompany.company_id,
                mybenefit_start_at: this.form.mybenefit_start_at,
                is_enabled_ewa: this.form.is_enabled_ewa,
                is_enabled_reimburse: this.form.is_enabled_reimburse,
                is_enabled_gajianduluan: this.form.is_enabled_gajianduluan,
                setting: this.form.setting,
              },
            }).then(() => {
              this.$message({
                title: 'Success',
                type: 'success',
                message: 'Successfully submit request',
              });
              // this.$parent.loadCompanies();
              this.onHide();
              // this.resetForm();
            }).catch((err) => {
              if (err.response) popupErrorMessages(err.response.data);
              console.log(err);
            });
            instance.confirmButtonLoading = false;
          }
          done();
        },
      }).catch(() => {});
    },
    resetForm() {
      // this.ewaTier = [];
      this.packetNames = "";
      this.ewaRequestLimit = "";
      this.adminFeeEwa = "";
      this.adminFeeType = "";
      this.packet = "";
    },
    onClose() {
      this.form = {
        package_id: '',
        mybenefit_start_at: moment().format('YYYY-MM-DD'),
        is_enabled_ewa: false,
        is_enabled_reimburse: false,
        is_enabled_gajianduluan: false,
        payoff_date: '',
        payroll_date: '',
        admin_fee_ewa: '',
        ewa_req_limit: '',
        // ewa_tier: []
      };
      this.onHide();
      this.$emit('onClose');
    },
    handlerSelectPackage() {
      this.loadBenefitDetail();
    },
    doShowModalCreatePackage() {
      this.showModalCreatePackage = true;
    },
    handlerChangeEwaLoan(value, type) {
      // if (this.form.is_enabled_ewa && this.form.is_enabled_gajianduluan) {
      //   if (type === 'ewa') {
      //     this.$set(this.form, 'is_enabled_gajianduluan', false);
      //   }
      //   if (type === 'loan') {
      //     this.$set(this.form, 'is_enabled_ewa', false);
      //   }
      // }
    },
    formatNumberWithSeparator(value) {
      const stringValue = String(value);

      const numericValue = stringValue.replace(/[^0-9.]/g, '');

      const parts = numericValue.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    formatNumber(value) {
      if (typeof value !== 'string') {
        value = String(value);
      }

      value = value.replace(/[^0-9.]/g, '');

      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    parseFormattedNumber(value) {
      return value.replace(/,/g, '');
    },
  },
  computed: {
    canAddEwaTier() {
      const lastRow = this.DataEwaTier[this.DataEwaTier.length - 1];
      return (
        // lastRow?.packet_name !== '' &&
        lastRow?.ewa_req_limit !== null &&
        lastRow?.admin_fee_ewa !== null &&
        lastRow?.admin_fee_type !== ''
      );
    },
    formattedAdminFeeEwa: {
        get() {
          return this.formatNumber(this.form.admin_fee_ewa);
        },
        set(value) {
          this.form.admin_fee_ewa = this.parseFormattedNumber(value);
        },
      },

      formattedEwaReqLimit: {
        get() {
          return this.formatNumber(this.form.ewa_req_limit);
        },
        set(value) {
          this.form.ewa_req_limit = this.parseFormattedNumber(value);
        },
      },
  },
  created() {
    for (let i = 1; i <= 31; i++) {
      this.options.push(i);
    }
  },
  watch: {
    isShown() {
      this.show = this.isShown;
      if (this.show) {
        // this.form.is_enabled_ewa = this.selectedCompany.is_enabled_ewa;
        // this.form.is_enabled_reimburse = this.selectedCompany.is_enabled_reimburse;
        // this.form.package_id = this.selectedCompany.mybenefit_package_id;
        // if (this.form.package_id) {
        //   this.form.mybenefit_start_at = this.selectedCompany.mybenefit_package_start_at;
        // }
        this.v$.form.$touch();
        this.loadMyBenefitList();
        this.loadCompanyDetail();
      }
    },
  },
};
</script>
