import ApiService from './api.service';

// actions
export const GET_CHANNEL = 'getChannel';
export const CREATE_CHANNEL = 'createChannel';

// mutations
export const SET_CHANNEL = 'setChannel';


const state = {
  channel: {},
};

const getters = {
    channel(state) {
    return state.channel; 
  },
};

const actions = {

    [CREATE_CHANNEL](context, param) {
        return new Promise((resolve, reject) => {
          ApiService.post(`api/vchat/v1/channels`, param.data)
            .then(async ({ data }) => {
              resolve(data);
            })
            .catch((err) => {
              reject(err);
            });
        });
      },
    [GET_CHANNEL](context, { page, query }) {
      return new Promise((resolve, reject) => {
      ApiService.get(`api/vchat/v1/channels`, query)
          .then(async ({ data }) => {
          await context.commit(SET_CHANNEL, data.data);
          resolve(data);
          })
          .catch((err) => {
          reject(err);
          });
      });
    },
};

const mutations = {
  [SET_CHANNEL](state, data) {
    state.channel = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};