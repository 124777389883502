<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title">{{ $route.name }} List</div>
        <el-button type="text" @click="showSearch = !showSearch"><font-awesome-icon icon="search" /></el-button>
      </div>
      <div class="card-body" v-if="showSearch">
        <div class="flex">
          <div class="w-1/2">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Transaction Date">
                <el-date-picker v-model="search.date" type="daterange" size="small" :picker-options="datePickerOptions"
                  class="w-full" range-separator="To" start-placeholder="Start date" end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input clearable size="small" type="text" v-model="search.search_keyword"
                  placeholder="Input search keyword" class="mr-2" />
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Transaction Status">
                <el-select size="small" v-model="search.status_filter" class="mr-2">
                  <el-option v-for="(item, index) in status_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="Filter Type">
                <el-select size="small" v-model="search.search_by" class="mr-2">
                  <el-option v-for="(item, index) in search_options" :key="index" :value="item.value"
                    :label="item.text" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="flex">
          <div class="w-1/2">
            &nbsp;
          </div>
          <div class="w-1/2">
            <el-form ref="form2" label-width="150px">
              <el-form-item label="Payment Vendor">
                <el-select size="small" v-model="search.payment_vendor_filter" class="mr-2">
                  <el-option v-for="(item, index) in payment_vendor_filter_options" :key="index" :value="item.value"
                    :label="item.label" />
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search" />
          Search</el-button>
        <el-button type="primary" @click="handlerDownloadBtn" size="small"><font-awesome-icon icon="download" />
          Download</el-button>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="25%">Transaction Date</th>
              <th width="25%">Invoice Number</th>
              <th width="20%">User Name</th>
              <th width="20%">User Email</th>
              <th width="10%">Amount</th>
              <th width="10%">Discount</th>
              <th width="10%">Admin Fee</th>
              <th>Payment Vendor</th>
              <th>Payment Method</th>
              <th width="20%" class="child2-sticky">Status</th>
              <th class="child1-sticky">Action</th>
            </tr>
          </thead>
          <tbody v-loading="isLoading">
            <tr v-for="(item, i) in list" :key="i">
              <td class="table-sticky-left">{{ item.created_at_str }}</td>
              <td class="table-sticky-left">{{ item.invoice_number }}</td>
              <td>{{ item.user_full_name }}</td>
              <td>{{ item.user_email }}</td>
              <td>{{ item.amount_str }}</td>
              <td>{{ item.discount_str }}</td>
              <td>{{ item.admin_fee_str }}</td>
              <td>{{ item.payment_vendor_str }}</td>
              <td>{{ item.pm_payment_name }}</td>
              <td class="text-nowrap text-muted child2-sticky"><span class="tag" :class="item.status_color_str">{{
                item.status_str }}</span></td>
              <td class="text-nowrap text-muted child1-sticky">
                <button @click="viewDetailTransaction(item)" title="View Detail" type="button"
                  class="btn btn-icon btn-flickr btn-sm"><font-awesome-icon icon="eye" /></button>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <pagination :page="page" :per_page="per_page" :list="list" :total_rows="total_rows"
        @pageChange="pageChangeHandler" />
    </div>
    <b-modal size="xl" v-model="showDetailTransaction" :title="'Transaction #' + selected_transaction.invoice_number"
      hide-footer @hide="resetDetailTransaction">
      <el-skeleton :loading="loading_detail" :rows="6" animated />
      <el-descriptions v-if="!loading_detail" class="margin-top mb-3" title="Order Information" :column="2" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            Transaction Date
          </template>
          {{ selected_transaction.created_at_str }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-phone"></i>
            Phone Number
          </template>
          {{ selected_transaction.user_phone_number }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            User Name
          </template>
          {{ selected_transaction.user_full_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-document"></i>
            Virtual Account Number
          </template>
          {{ selected_transaction.virtual_account_number || '-' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-message"></i>
            Email
          </template>
          {{ selected_transaction.user_email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-wallet"></i>
            Payment Method
          </template>
          {{ selected_transaction.pm_payment_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-finished"></i>
            Payment Status
          </template>
          <span class="tag" :class="selected_transaction.status_color_str">{{ selected_transaction.status_str }}</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-c-scale-to-original"></i>
            Payment Ref 
          </template>
          {{ selected_transaction.details?.[0]?.payment_ref || '-' }}
        </el-descriptions-item>
      </el-descriptions>
      <div class="table-responsive">
        <table v-if="!loading_detail" class="table table-vcenter w-full">
          <thead>
            <tr>
              <th width="25%">Product Name</th>
              <th>Type</th>
              <th>Destination Number</th>
              <th>Vendor</th>
              <th>Qty</th>
              <th>Base Price</th>
              <th>Sell Price</th>
              <th>Ref. Number</th>
              <th>Status</th>
              <th>Total</th>
              <!-- <th v-if="user_email === 'it_idn@venteny.com'">Action</th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in selected_transaction.details" :key="i">
              <td>{{ item.product.name }}</td>
              <td>{{ item.source_type_str }}</td>
              <td>{{ item.hp || "-" }}</td>
              <td>{{ item.vendor ? item.vendor.vendor_name : "-" }}</td>
              <td>{{ item.product.quantity }}</td>
              <td>{{ item.product.base_price }}</td>
              <td>{{ item.product.sell_price }}</td>
              <td>{{ item.ref_vendor }}</td>
              <td>
                <el-popover v-if="item.order_detail_status == 0 && item.order_detail_message" placement="left" width="200"
                  trigger="hover" :content="item.order_detail_message">
                  <span slot="reference" class="tag" :class="item.status_color">{{ item.status_str }}</span>
                </el-popover>
                <span v-else class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>{{ item.product.total_price }}</td>
              <!-- <td v-if="user_email === 'it_idn@venteny.com'">  -->
              <td> 
                <div>
                  <el-button @click="toggleCollapseProduct(item.id)" class="btn-outline-primary" size="small"><i  v-if="loading" class="fa fa-spinner fa-spin"></i> Check Status</el-button>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="13">
                <b-collapse id="product-status" v-model="collapseOpenedProduct">
                  <b-card><br>
                    <div v-if="loadingProductStatus" class="d-flex justify-content-center align-items-center" style="height: 200px;">
                      <div class="spinner-border text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <p> Product Status </p>
                      <div class="table-responsive">
                        <table class="table table-vcenter w-full">
                          <thead>
                            <tr>
                              <th>Ref ID</th>
                              <th>Price</th>
                              <th>Balance</th>
                              <th>Transaction ID</th>
                              <th>RC</th>
                              <th>Code</th>
                              <th>HP</th>
                              <th>Message</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in product_status" :key="item.id">
                              <td>{{ item.ref_id }}</td>
                              <td>{{ formatCurrency(item.price) }}</td>
                              <td>{{ item.balance }}</td>
                              <td>{{ item.tr_id }}</td>
                              <td>{{ item.rc }}</td>
                              <td>{{ item.code }}</td>
                              <td>{{ item.hp }}</td>
                              <td>
                                <span size="xs" v-if="item.message === 'SUCCESS'" class="tag tag-success">{{ item.message }}</span>
                                <span v-else-if="item.message === 'FAILED'" class="tag tag-danger">{{ item.message }}</span>
                                <span v-else-if="item.message === 'PROCESS'" class="tag tag-info">{{ item.message }}</span>
                                <span v-else-if="item.message === 'WAITING PAYMENT'" class="tag tag-warning">{{ item.message }}</span>
                                <span v-else>{{ item.message }}</span> 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </b-card>
                </b-collapse>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="9"><i>Admin Fee</i></td>
              <td>{{ selected_transaction.admin_fee_str }}</td>
              <td></td>
            </tr>
            <tr v-for="(item, i) in selected_transaction.additional_data" :key="i">
              <td colspan="9"><i>{{ item.additional_name }}</i></td>
              <td><i>{{ item.additional_fee_str }}</i></td>
            </tr>
            <tr v-for="(item, i) in selected_transaction.detail_discount" :key="i">
              <td colspan="9"><i>{{ item.promo_name }}</i></td>
              <td><i>-{{ item.promo_discount_str }}</i></td>
            </tr>
            <tr style="background-color: #f4f6fa; font-weight: bold;">
              <td colspan="9">Subtotal</td>
              <td>{{ selected_transaction.amount_str }}</td>
              <!-- <td v-if="user_email === 'it_idn@venteny.com'"></td> -->
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { GET_TRANSACTIONS, DETAIL_TRANSACTION, DOWNLOAD_TRANSACTIONS, RESET_TRANSACTION } from '@/store/modules/transactions';
import { STATUS_PRODUCT } from '@/store/modules/app_user';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import vouchers from '../../store/modules/vouchers';

export default {
  components: { Pagination },
  name: 'Transactions',
  metaInfo: {
    title: 'Transactions',
  },
  data() {
    return {
      showDetailTransaction: false,
      showSearch: true,
      search_options: [
        { value: 'invoice_number', text: 'Invoice Number' },
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      emptyStateImage,
      merchantList: [],
      merchant_id: '',
      filter_merchant_loading: false,
      per_page: 20,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      user_email: null,
      collapseOpenedProduct: false,
      search_keyword: '',
      search_by: '',
      search_merchant_keyword: '',
      selected_transaction: {
        user: {},
      },
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'invoice_number',
        search_keyword: '',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
        payment_vendor_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      
      summary_vendor: [],
      status_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Waiting Payment',
          value: 1,
        },
        {
          label: 'Process',
          value: 2,
        },
        {
          label: 'Success',
          value: 3,
        },
        {
          label: 'Expired',
          value: 4,
        },
        {
          label: 'Failed',
          value: 5,
        },
      ],
      payment_vendor_filter_options: [
        {
          label: 'All',
          value: '',
        },
        {
          label: 'Qris',
          value: 'qris',
        },
        {
          label: 'Espay',
          value: 'espay',
        },
        {
          label: 'Dipay',
          value: 'dipay',
        },
        {
          label: 'M-bayar',
          value: 'e2pay',
        },
        {
          label: 'Xendit',
          value: 'xendit',
        },
        {
          label: 'Motion Pay',
          value: 'motionpay',
        },
      ],
      product_status: [],
      loadingProductStatus: false,
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    const today = moment().format('YYYY-MM-DD');
    this.search.date = [today, today];
    this.search.start_date = today;
    this.search.end_date = today;
    await this.getList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchMerchant(query) {
      this.search_merchant_keyword = query;
      this.getMerchantList();
    },
    handlerDownloadBtn() {
      this.search.action = 'download';
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(this.search.action === 'download' ? DOWNLOAD_TRANSACTIONS : GET_TRANSACTIONS, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        search_keyword: this.search_keyword,
        ...this.search,
        // filter_source_type: 'insurance',
      }).then((result) => {
        if (this.search.action === 'download') {
          const anchor = document.createElement('a');
          anchor.href = `data:${result.headers['content-type']},${encodeURIComponent(result.data)}`;
          anchor.target = '_blank';
          anchor.download = `Transaction-Report-${this.search.start_date}-${this.search.end_date}.csv`;
          anchor.click();
        } else {
          const { rows, count } = this.$store.getters.transactions;
          this.total_rows = count || 0;
          this.list = rows;
        }
      }).catch((err) => {
        console.log(err);
      });
      this.isLoading = false;
    },
    async viewDetailTransaction(item) {
      try {
      this.collapseOpenedProduct = false,
      this.selected_transaction = item;
      // console.log("selected_transaction", this.selected_transaction)
      this.showDetailTransaction = true;
      this.loading_detail = true;
      await this.$store.dispatch(DETAIL_TRANSACTION, item.invoice_number);
        this.transactionDetails = this.$store.getters.trx.details.map((v) => {
          const payment_ref = v.order?.payment_ref || '-';
          return {
            order_id: v.order_id,
            source_type: v.source_type,
            product_vendor: v.product_vendor,
            id: v.id,
            payment_ref: v.payment_ref
          };
        });
        this.selected_transaction.details = this.$store.getters.trx.details.map((v) => {
        const {
          detail_product, quantity, sell_price, total_price, v_price, base_price, transaction_qri, amount, insurance_order, voucher, order
        } = v;
        v.payment_ref = order.payment_ref;
        v.source_type_str = upperFirst(v.source_type);
        if (v.source_type === 'v_price') {
          v.source_type_str = 'PPOB';
        } else if (v.source_type === 'special_program') {
          v.source_type_str = 'Spesial Program';
        } else if (v.source_type === 'lms') {
          v.source_type_str = 'Academy';
        }
        v.product = {};
        if (v.source_type === 'voucher') {
          v.product = {
            name: detail_product ? detail_product?.voucher_name : voucher.voucher_name,
            base_price: detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price ? `Rp. ${detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price.toLocaleString('id-ID')}` : detail_product ? detail_product?.voucher_base_price : voucher.voucher_base_price,
            sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
          };
        } else if (v.source_type === 'v_price') {
          v.product = {
            name: v_price.v_price_type ? [v_price.v_price_type, v_price.nominal].join(' ') : v_price.product_code,
            base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
            sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
          };
          if (v_price.vendor_id.toString() === '8') {
            if (v.detail_product.provider && v.detail_product.nominal) {
              v.product.name = [v.detail_product.provider, v.detail_product.nominal].join(' ');
            } else {
              v.product.name = v_price.nominal;
            }
          }
        } else if (v.source_type === 'academic') {
          v.product = {
            name: detail_product.academic_name,
            base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
            sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
          };
          // name: transaction_qri.transaction_qris_name || '',
        } else if (v.source_type === 'qris') {
          v.product = {
             name: transaction_qri && transaction_qri.transaction_qris_name ? transaction_qri.transaction_qris_name : '',
            base_price: '-',
            sell_price: '-',
            quantity: 1,
            total_price: amount ? `Rp. ${amount.toLocaleString('id-ID')}` : amount,
          };
        } else if (v.source_type === 'insurance') {
          v.product = {
            name: insurance_order.insurance_name,
            base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
            sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
          };
        } else if (v.source_type === 'special_program') {
          v.product = {
            name: detail_product.productName,
            base_price: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : base_price,
            sell_price: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : sell_price,
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : total_price,
          };
        } else if (v.source_type === 'lms') {
          v.product = {
            name: detail_product.name,
            base_price,
            base_price_str: base_price ? `Rp. ${base_price.toLocaleString('id-ID')}` : '-',
            sell_price,
            sell_price_str: sell_price ? `Rp. ${sell_price.toLocaleString('id-ID')}` : '-',
            quantity,
            total_price: total_price ? `Rp. ${total_price.toLocaleString('id-ID')}` : '-',
          };
        }
        v.status_str = 'Failed';
        v.status_color = 'tag-danger';

        if (v.order_detail_status === 1) {
          v.status_str = 'Process';
          v.status_color = 'tag-info';
          if (this.selected_transaction.status === 1) {
            v.status_str = 'Waiting payment';
            v.status_color = 'tag-warning';
          }
        } else if (v.order_detail_status === 2) {
          v.status_str = 'Success';
          v.status_color = 'tag-success';
        } else if (v.order_detail_status === 3) {
          v.status_str = 'Refund';
          v.status_color = 'tag-teal';
        }
        return v;
      });
    } catch (error) {
    } finally {
      this.loading_detail = false;
    }
    },
    formatCurrency(value) {
      if (value) {
        const hasDecimalPart = value % 1 !== 0;
        const formattedIntegerPart = Math.floor(value).toLocaleString('id-ID');
        const formattedValue = `Rp. ${formattedIntegerPart}`;
        return hasDecimalPart ? `Rp. ${value.toLocaleString('id-ID')}` : formattedValue;
      } else {
        return value;
      }
    },
    toggleCollapseProduct(id) {
      if (!this.collapseOpenedProduct) {
        this.checkProductStatus(id);
        this.collapseOpenedProduct = true;
      } else {
        this.collapseOpenedProduct = false;
      }
    },
    showUserData(data, value) {
      // return data?.user?.[value];
      return data?.user?.[value];
    },
    resetDetailTransaction(){
      this.$store.dispatch(RESET_TRANSACTION)
    },
    async checkProductStatus(id) {
      this.loadingProductStatus = true
      this.product_status = [];
      console.log('transactionDetails checkProductStatus ', this.transactionDetails);
      console.log('ID ', id);
      try {
        if (!this.transactionDetails || this.transactionDetails.length === 0) {
          console.error("Transaction details are  empty");
          return;
        }
        const firstTransactionDetails = this.transactionDetails[0];
        const { order_id, source_type, product_vendor } = firstTransactionDetails;
        if (!order_id || !source_type || !product_vendor) {
          console.error("Incomplete data for request");
          return;
        }
        const requestBody = {
          data: {
            order_id,
            source_type,
          },
          product_vendor,
        };
        const response = await this.$store.dispatch(STATUS_PRODUCT, requestBody);
        this.product_status = response.data.rows;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingProductStatus = false; 
      }
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}
</style>
