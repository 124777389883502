<template>
  <div class="container-xl">
    <div class="row row-deck row-cards mb-3">
      <div class="col-sm-6 col-lg-3" v-for="(item, i) in summary_vendor" :key="i" v-loading="loading.summary_vendor">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">Rp. {{ parseInt(item.total_transaction).toLocaleString() }}</div>
            <div class="d-flex mb-2">
              <div>Transaction Amount {{item.payment_vendor.toUpperCase()}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">Rp. {{ total_amount.toLocaleString() }}</div>
            <div class="d-flex mb-2">
              <div>Total Transaction Amount</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3"> {{ formattedBalance }}</div>
            <div class="d-flex mb-2">
              <div>Ultra Voucher Balance</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{ formattedBalanceMp }}</div>
            <div class="d-flex mb-2">
              <div>Mobile Pulsa Balance</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-deck row-cards mb-3">
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body" v-loading="loading.academy">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{totalAcademy}} </div>
            <div class="d-flex mb-2">
              <div>Number of Videos Purchased </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body" v-loading="loading.academy">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{totalAcademyView}} </div>
            <div class="d-flex mb-2">
              <div>Number of Videos Viewed </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body" v-loading="loading.academy">
            <div class="d-flex align-items-center">
              <div class="subheader"></div>
            </div>
            <div class="h1 mb-3">{{totalAcademyLove}} </div>
            <div class="d-flex mb-2">
              <div>Number of Videos Loved </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-deck row-cards">
      <div class="col-lg-12">
        <div class="row row-cards">
          <div class="col-12">
            <div class="card">
              <div class="card-body" v-loading="loading.summary_user">
                <h3 class="card-title">Daily New User</h3>
                <apexchart v-if="!loading.summary_user" class="mb-2" height="350" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body" v-loading="loading.trx">
                <h3 class="card-title">Daily Transaction</h3>
                <apexchart v-if="!loading.summary_user" class="mb-2" height="350" id="apexpie" :options="trxdaily_chart.options" :series="trxdaily_chart.series"/>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body" v-loading="loading.trx">
                <h3 class="card-title">Daily Transaction (Amount)</h3>
                <apexchart v-if="!loading.summary_user" class="mb-2" height="350" id="apexpie" :options="trxdailyamount_chart.options" :series="trxdailyamount_chart.series"/>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-xl-6">
            <div class="card">
              <div class="card-body" v-loading="loading.summary_product">
                <h3 class="card-title">Daily Trx Product (Total)</h3>
                <apexchart class="mb-2" type="pie" width="500" :options="trxproductdailytotal_chart.options" :series="trxproductdailytotal_chart.series"></apexchart>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" v-loading="loading.summary_product">
                <h3 class="card-title">Total Trx Product</h3>
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in ListProduct" :key="index">
                      <td>{{ item.source_type }}</td>
                      <td>{{ item.total }}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" v-loading="loading.top_voucher">
                <h3 class="card-title">Top 10 Voucher</h3>
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <th>Voucher Name</th>
                      <th>Total Sold</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in topVoucher" :key="index">
                      <td>{{ item.voucher_name }}</td>
                      <td>{{ item.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" v-loading="loading.academy">
                <h3 class="card-title">Top 10 Academy</h3>
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <th>Academy Name</th>
                      <th>Total View</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in topAcademy" :key="index">
                      <td>{{ item.name }}</td>
                      <td>{{ item.count }}</td>
                      </tr>
                  </tbody>
                </table>
                <el-empty description="No data found" v-if="topAcademy.length == 0" :image="emptyStateImage"></el-empty>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" v-loading="loading.most_used_promo">
                <h3 class="card-title">Most Used Promo</h3>
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <th>Promo Name</th>
                      <th>Usage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in most_used_promo" :key="index">
                      <td>{{ item.promo_name }}</td>
                      <td>{{ item.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="card">
              <div class="card-body" v-loading="loading.current_promo">
                <h3 class="card-title">Current Promo</h3>
                <table class="table table-vcenter">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Code</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in ListPromo" :key="index">
                      <td>{{ item.promo_name }}</td>
                      <td>{{ item.promo_type_str }}</td>
                      <td>{{ item.config.voucher_code }}</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GET_VENDOR_BALANCE_FINANCE } from '@/store/modules/transactions';
import moment from 'moment';
import { orderBy, sumBy } from 'lodash';

import {
  SUMMARY_TRANSACTION, DAILY_CHART_TRANSACTION, VOUCHER_SUMMARY_TRANSACTION, MOST_USED_PROMO, GET_TRANSACTIONS_PRODCUTS,
} from '@/store/modules/transactions';
import { GET_PROMOS } from '@/store/modules/promo';
import { ANALYTIC_APPUSERS } from '@/store/modules/app_user';
import { ANALYTIC_ACADEMYS } from '@/store/modules/academys';
import emptyStateImage from '@/assets/images/empty-state.png';

export default {
  name: 'Dashboard',
  metaInfo: {
    title: 'Dashboard',
  },
  data() {
    return {
      current_balance: null,
      current_balance_Mp: null,
      balances_list: [],
      formattedBalance:  null,
      formattedBalanceMp: null,
      summary_vendor: [],
      emptyStateImage,
      loading: {
        summary_vendor: false,
        summary_user: false,
        trx: false,
        top_voucher: false,
        most_used_promo: false,
        academy: false,
        current_promo: false,
        summary_product: false,
      },
      search: {
        start_date: moment().startOf('month').format('YYYY-MM-DD'),
        end_date: moment().endOf('month').format('YYYY-MM-DD'),
      },
      analytic: {},
      trx_analytic: {},
      total_amount: 0,
      register_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            stacked: true,
            // sparkline: {
            //   enabled: true,
            // },
            // animations: {
            //   enabled: true,
            // },
          },
          legend: {
            show: true,
            position: 'bottom',
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: [0.16, 1],
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            min: moment().startOf('month').format('YYYY-MM-DD'),
            max: moment().endOf('month').format('YYYY-MM-DD'),
            type: 'datetime',
            categories: [],
            labels: {
              show: true,
            },
          },
          yaxis: {
            labels: {
              show: true,
              maxWidth: 200,
            },
          },
          tooltip: {
            theme: 'light',
            shared: true,
            intersect: false,
          },
          labels: [],
        },
        series: [],
      },
      trxdaily_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            stacked: true,
          },
          legend: {
            show: true,
            position: 'bottom',
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: [0.16, 1],
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            min: moment().startOf('month').format('YYYY-MM-DD'),
            max: moment().endOf('month').format('YYYY-MM-DD'),
            type: 'datetime',
            categories: [],
            labels: {
              show: true,
            },
          },
          yaxis: {
            labels: {
              show: true,
              maxWidth: 200,
            },
          },
          tooltip: {
            theme: 'light',
            shared: true,
            intersect: false,
          },
          labels: [],
        },
        series: [],
      },
      trxdailyamount_chart: {
        options: {
          chart: {
            type: 'area',
            fontFamily: 'inherit',
            height: 40.0,
            stacked: true,
          },
          legend: {
            show: true,
            position: 'bottom',
          },
          dataLabels: {
            enabled: false,
          },
          fill: {
            opacity: [0.16, 1],
            type: 'solid',
          },
          stroke: {
            width: 2,
            lineCap: 'round',
            curve: 'smooth',
          },
          xaxis: {
            min: moment().startOf('month').format('YYYY-MM-DD'),
            max: moment().endOf('month').format('YYYY-MM-DD'),
            type: 'datetime',
            categories: [],
            labels: {
              show: true,
            },
          },
          yaxis: {
            labels: {
              show: true,
              maxWidth: 200,
            },
          },
          tooltip: {
            theme: 'light',
            shared: true,
            intersect: false,
            y: {
              formatter: (y) => {
                if (typeof y !== 'undefined') {
                  return `Rp. ${y.toLocaleString()}`;
                }
                return y;
              },
            },
          },
          labels: [],
        },
        series: [],
      },
      trxproductdailytotal_chart: {
        options: {
          chart: {
            width: 500,
            type: 'pie',
          },
          labels: [],
        },
        series: [],
      },
      topVoucher: [],
      academyList: [],
      topAcademy: [],
      totalAcademy: 0,
      totalAcademyView: 0,
      totalAcademyLove: 0,
      most_used_promo: [],
      today: moment().format('YYYY-MM-DD'),
      ListPromo: [],
      ListProduct: [],
    };
  },
  mounted() {
    this.summaryVendor();
    this.getAnalyticUser();
    this.getTrxDaily();
    this.topVoucherSold();
    this.topAcademyViewed();
    this.mostUsedPromo();
    this.getListPromo();
    this.getListProductSummary();
    this.getBalance()
    this.getBalanceMp()
  },
  methods: {
    getBalance(vendor = '') {
      const selectedVendor = 'ultra_voucher';
      this.$store.dispatch(GET_VENDOR_BALANCE_FINANCE, {
        vendor: selectedVendor,
      }).then(({ data }) => {
        if (data.balance) {
          this.formattedBalance = `Rp.${data.balance.toLocaleString()}`;
        } 
      }).catch((err) => {
        console.error('Error fetching vendor balance:', err);
      });
    },
    getBalanceMp(vendor = '') {
      const selectedVendor = 'mobile_pulsa';
      this.$store.dispatch(GET_VENDOR_BALANCE_FINANCE, {
        vendor: selectedVendor,
      }).then(({ data }) => {
        if (data.balance) {
          this.formattedBalanceMp = `Rp.${data.balance.toLocaleString()}`;
        }
      }).catch((err) => {
        console.error('Error fetching vendor balance:', err);
      });
    },
    async summaryVendor() {
      this.loading.summary_vendor = true;
      const response = await this.$store.dispatch(SUMMARY_TRANSACTION, { ...this.search });
      if (response.data) {
        this.summary_vendor = response.data.summary;
        this.loading.summary_vendor = false;
        this.total_amount = sumBy(this.summary_vendor, (v) => parseInt(v.total_transaction, 0));
      }
    },
    async getAnalyticUser() {
      this.loading.summary_user = true;
      await this.$store.dispatch(ANALYTIC_APPUSERS, this.search).catch(() => {});
      this.analytic = this.$store.getters.analytic_appuser;
      this.analytic.login_daily = this.analytic.login_daily.filter((v) => v && v.login_date);
      this.analytic.register_daily = this.analytic.register_daily.filter((v) => v && v.register_date);

      const obj = orderBy(this.analytic.register_daily, 'register_date').reverse().splice(0, 30).reverse();
      this.register_chart.series.push({
        name: 'Daily New User',
        type: 'area',
        data: obj.map((v) => v.count),
      });
      this.register_chart.options.labels = obj.map((v) => v.register_date);
      this.register_chart.options.xaxis.categories = obj.map((v) => v.register_date);

      // let obj_login = orderBy(this.analytic.login_daily, 'login_date');
      // obj_login = obj_login.reverse().splice(0, 30).reverse();
      // this.register_chart.series.push({
      //   name: 'Daily Login',
      //   type: 'line',
      //   data: obj_login.map((v) => v.count),
      // });
      // this.login_chart.options.labels = obj_login.map((v) => v.login_date);
      // this.login_chart.options.xaxis.categories = obj_login.map((v) => v.login_date);

      setTimeout(() => {
        this.loading.summary_user = false;
      }, 500);
    },
    async getTrxDaily() {
      this.loading.trx = true;
      try {
        const response = await this.$store.dispatch(DAILY_CHART_TRANSACTION, this.search);
        this.trx_analytic = response.data;
        const obj = this.trx_analytic.summary;
        this.trxdaily_chart.series.push({
          name: 'Daily Transaction',
          type: 'area',
          data: obj.map((v) => v.count),
        });
        this.trxdaily_chart.options.labels = obj.map((v) => v.timestamp);
        this.trxdaily_chart.options.xaxis.categories = obj.map((v) => v.timestamp);

        this.trxdailyamount_chart.series.push({
          name: 'Daily Amount Transaction',
          type: 'area',
          data: obj.map((v) => v.total_amount),
        });
        this.trxdailyamount_chart.options.labels = obj.map((v) => v.timestamp);
        this.trxdailyamount_chart.options.xaxis.categories = obj.map((v) => v.timestamp);
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        this.loading.trx = false;
      }, 500);
    },
    async topVoucherSold() {
      this.loading.top_voucher = true;
      try {
        const response = await this.$store.dispatch(VOUCHER_SUMMARY_TRANSACTION, this.search);
        this.topVoucher = response.data.summary;
      } catch (error) {
        console.log(error);
      }
      this.loading.top_voucher = false;
    },
    async topAcademyViewed() {
      this.loading.academy = true;
      try {
        const response = await this.$store.dispatch(ANALYTIC_ACADEMYS, this.search);
        this.academyList = response.data;
        this.topAcademy = this.academyList.top_view_daily.filter((v) => v.created_at === this.today);
        this.totalAcademy = this.academyList.purchase_video_daily.find((v) => v.createdAt === this.today)?.count || 0;
        this.totalAcademyView = this.academyList.top_view_daily.find((v) => v.created_at === this.today)?.count || 0;
        this.totalAcademyLove = this.academyList.loved_video_daily.find((v) => v.createdAt === this.today)?.count || 0;
      } catch (error) {
        console.log(error);
      }
      this.loading.academy = false;
    },
    async mostUsedPromo() {
      this.loading.most_used_promo = true;
      try {
        const response = await this.$store.dispatch(MOST_USED_PROMO, this.search);
        this.most_used_promo = response.data.rows.filter((v) => v.promo_name);
      } catch (error) {
        console.log(error);
      }
      this.loading.most_used_promo = false;
    },
    async getListPromo() {
      this.loading.current_promo = true;
      await this.$store.dispatch(GET_PROMOS, {
        page: this.page,
        per_page: this.per_page,
        search_by: 'promo_status',
        search_keyword: 1,
        // review_status_filter: this.review_status_filter,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.promos;
      this.total_rows = count || 0;
      this.ListPromo = rows;
      this.loading.current_promo = false;
    },
    async getListProductSummary() {
      this.loading.summary_product = true;
      await this.$store.dispatch(GET_TRANSACTIONS_PRODCUTS, {
        page: this.page,
        per_page: this.per_page,
        start_date: this.start_date,
        end_date: this.end_date,
      }).catch(() => {});
      const { rows, count } = this.$store.getters.transaction_products;
      this.total_rows = count || 0;
      this.ListProduct = rows;

      this.ListProduct.forEach((element) => {
        this.trxproductdailytotal_chart.series.push(element.total);
        this.trxproductdailytotal_chart.options.labels.push(element.source_type);
      });

      this.loading.summary_product = false;
    },
  },
};
</script>
