import moment from 'moment';
import ApiService from './api.service';

// actions
export const GET_APPUSERS = 'getAppUsers';
export const NEW_GET_APPUSERS = 'newGetAppUsers';
export const GET_POPUP_BANNER = 'getPopupBanner';
export const CREATE_POPUPBANNER = 'createPopupBanner';
export const UPDATE_POPUP_BANNER = 'updatePopupBanner';
export const UPDATE_STATUS_POPUP_BANNER = 'updateStatusPopupBanner';
export const GET_APPUSERS_BY_COMPANY = 'getAppUsersByCompany';
export const ANALYTIC_APPUSERS = 'getAnalyticAppUsers';
export const EMBED_VOUCHER = 'embedVoucher';
export const BLOCK_UNBLOCK_APPUSER = 'blockUnblockAppUser';
export const IMPORT_PRIVATE_USER = 'importPrivateUser';
export const IMPORT_PRIVATE_USER_PROMO = 'importPrivateUserPromo';
export const SINGLE_IMPORT_PRIVATE_USER = 'singleImportPrivateUser';
export const SINGLE_REMOVE_PRIVATE_USERS = 'singleRemovePrivateUsers';

// new
// export const UPLOAD_FINANCE = 'uploadFinance';
export const UNLINK = 'unlink';
export const RESET = 'reset';
export const INQUIRY_VA = 'inquiryVa';
export const GET_REFUND_OTP = 'getRefundOtp';
export const INFO_STATUS_DIPAY = 'infoStatusDipay';
export const REFUND_VERIFY = 'refundVerify';
// export const REFUND_MOTION_PAY_VERIFY = 'refundMotionPayVerify';
export const STATUS_PRODUCT = 'statusProduct';
export const STATUS_DIPAY = 'statusDipay';
export const LOG_DIPAY = 'logDipay';
export const REPURCHASE_VERIFY = 'repurchaseVerify';
export const GET_REPURCHASE_OTP = 'getRepurchaseOtp';
// REFERRAL PROGRAM
export const CREATE_REFERRAL_PROGRAM = 'createReferralProgram';
export const GET_REFERRAL_PROGRAM = 'getReferralProgram';
export const EDIT_REFERRAL_PROGRAM = 'editReferralProgram';
// REFERRAL CODE
export const EDIT_REFERRAL_CODE = 'editReferralCode';
export const CREATE_REFERRAL_CODE = 'createReferralCode';
export const GET_REFERRAL_CODE = 'getReferralCode';
// CATEGORY 
export const GET_CATEGORIES = 'getCategories';
export const CREATE_CATEGORY = 'createCategory';
export const EDIT_CATEGORY = 'editCategory';
// GET_REFERRAL_CLAIMS 
export const GET_REFERRAL_CLAIMS = 'getReferralClaims';

// mutations
export const SET_INQUIRY_VA = 'setInquiryVa';
export const SET_STATUS_DIPAY = 'setStatusDipay';
export const SET_POPUP_BANNER = 'setPopupBanner';
export const SET_APPUSERS = 'setAppUsers';
export const NEW_SET_APPUSERS = 'newSetAppUsers';
export const SET_ANALYTIC_APPUSERS = 'setAnalyticAppUsers';
export const SET_CATEGORIES = 'setCategories';
export const SET_REFERRAL_PROGRAM = 'setReferralProgram';
export const SET_REFERRAL_CLAIMS = 'setReferralClaims';

const kyc_status = {
  NOT_SUBMITED: {
    color: 'tag-default',
  },
  SUBMITED: {
    color: 'tag-warning',
  },
  REJECTED: {
    color: 'tag-danger',
  },
  APPROVED: {
    color: 'tag-primary',
  },
};
const status = {
  3: {
    text: 'Coming Soon',
    color: 'tag-default',
  },
  2: {
    text: 'Maintenance',
    color: 'tag-warning',
  },
  1: {
    text: 'Active',
    color: 'tag-success',
  },
  0: {
    text: 'Inactive',
    color: 'tag-danger',
  },
};
const status_banner = {
  true: {
    text: 'Active',
    color: 'tag-success',
  },
  false: {
    text: 'Inactive',
    color: 'tag-danger',
  },
};
const referral_claim_status = {
  5: {
    text: 'Company Restrict',
    color: 'tag-default',
  },
  4: {
    text: 'Hold',
    color: 'tag-default',
  },
  3: {
    text: 'Expired',
    color: 'tag-danger',
  },
  2: {
    text: 'Failed',
    color: 'tag-danger',
  },
  1: {
    text: 'Success',
    color: 'tag-success',
  },
  0: {
    text: 'Submitted',
    color: 'tag-warning',
  },
};

const referral_status = {
  5: {
    text: 'Company Restrict',
    color: 'tag-default',
  },
  4: {
    text: 'Hold',
    color: 'tag-default',
  },
  3: {
    text: 'Expired',
    color: 'tag-danger',
  },
  2: {
    text: 'Failed',
    color: 'tag-danger',
  },
  1: {
    text: 'Success',
    color: 'tag-success',
  },
  0: {
    text: 'Submitted',
    color: 'tag-warning',
  },
};

const state = {
  status_dipay: {},
  inquiry_va: {},
  app_users: {},
  new_app_users: {},
  analytic_appuser: {},
  get_categories: {},
  get_referral_program: {},
  get_referral_claims: {},
  banners: {}
};

const getters = {
  status_dipay(state) {
    return state.status_dipay;
  },
  inquiry_va(state) {
    return state.inquiry_va;
  },
  app_users(state) {
    return state.app_users;
  },
  new_app_users(state) {
    return state.new_app_users;
  },
  analytic_appuser(state) {
    return state.analytic_appuser;
  },
  get_categories(state) {
    return state.get_categories;
  },
  get_referral_program(state) {
    return state.get_referral_program;
  },
  get_referral_claims(state) {
    return state.get_referral_claims;
  },
  // new
  banners(state) {
    return state.banners;
  },
};

const actions = {
  [GET_APPUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/users-app', param)
        .then(async ({ data }) => {
          await context.commit(SET_APPUSERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [NEW_GET_APPUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/users-app/list', param)
        .then(async ({ data }) => {
          await context.commit(NEW_SET_APPUSERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_APPUSERS_BY_COMPANY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/companies/list/users-app', param)
        .then(async ({ data }) => {
          await context.commit(SET_APPUSERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EMBED_VOUCHER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/users-app/${param.id}/voucher`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UNLINK](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/dipay/${param.owned_by}/accounts/unlink`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [RESET](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`/api/users-app/${param.owned_by}/pin/reset`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INQUIRY_VA](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/dipay/${param.owned_by}/accounts/va`, param.data)
        .then(async ({ data }) => {
          await context.commit(SET_INQUIRY_VA, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [REFUND_MOTION_PAY_VERIFY](context, param) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.post(`api/motionpay/${param.owned_by}/payment/refund`, param.data)
  //       .then(({ data }) => {
  //         resolve(data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  // NEW
  [GET_REFUND_OTP](context, param) {
    return new Promise((resolve, reject) => {
      const companyId = param.company_id;
      ApiService.get(`api/transaction/refund/otp?company_id=${companyId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REFUND_VERIFY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/transaction/refund/verify`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STATUS_PRODUCT](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/product/${param.product_vendor}/voucher/status`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [STATUS_DIPAY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/dipay/${param.owned_by}/transfer/otc/status`, param)
        .then(async ({ data }) => {
          resolve(data);
          // console.log('res ==>', param)
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [LOG_DIPAY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/dipay/${param.owned_by}/transfer/logs`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_REPURCHASE_OTP](context, param) {
    return new Promise((resolve, reject) => {
      const companyId = param.company_id;
      ApiService.get(`api/transaction/repurchase/otp?company_id=${companyId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [REPURCHASE_VERIFY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/transaction/repurchase/verify`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // REFERRAL PROGRAM
  [CREATE_REFERRAL_PROGRAM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/referral/programs`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EDIT_REFERRAL_PROGRAM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/referral/programs/${param.id}`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_REFERRAL_PROGRAM](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/referral/programs', param)
        .then(async ({ data }) => {
          await context.commit(SET_REFERRAL_PROGRAM, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // REFERRAL CODE
  [CREATE_REFERRAL_CODE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/codes`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [EDIT_REFERRAL_CODE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/codes`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_REFERRAL_CODE](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/v1/codes`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_POPUP_BANNER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('/api/setting/v1/popup-banner', param)
      // ApiService.get('/api/popup-banner', param)
      // ApiService.get('/api/setting/popup-banner', param)
        .then(async ({ data }) => {
          await context.commit(SET_POPUP_BANNER, data.data);
          resolve(data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [UPDATE_POPUP_BANNER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/setting/v1/popup-banner/${param._id}`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [UPDATE_STATUS_POPUP_BANNER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/setting/v1/popup-banner/${param._id}`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [INFO_STATUS_DIPAY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get(`api/dipay/${param.owned_by}/accounts`, param.data)
        .then(async ({ data }) => {
          await context.commit(SET_STATUS_DIPAY, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [CREATE_POPUPBANNER](context, param) {
    return new Promise((resolve, reject) => {
      // ApiService.post(`api/dipay/${param.owned_by}/accounts/unlink`, param.data)
      ApiService.post(`api/setting/v1/popup-banner`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // CATEGORY
  [CREATE_CATEGORY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.post(`api/v1/categories`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // /:id
  [EDIT_CATEGORY](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/v1/categories`, param.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }, 
  [GET_CATEGORIES](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/referral/categories', param)
        .then(async ({ data }) => {
          await context.commit(SET_CATEGORIES, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [GET_REFERRAL_CLAIMS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/referral/claims', param)
        .then(async ({ data }) => {
          await context.commit(SET_REFERRAL_CLAIMS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [ANALYTIC_APPUSERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/analytic/users-app', param)
        .then(async ({ data }) => {
          await context.commit(SET_ANALYTIC_APPUSERS, data.data);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [BLOCK_UNBLOCK_APPUSER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`api/users-app/${param.id}/${param.action}`)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // [UPLOAD_FINANCE](context, param) {
  //   return new Promise((resolve, reject) => {
  //     // Use ApiService.put for the new endpoint
  //     ApiService.post(`/api/finance/transactions/import`, param.data)
  //       .then(async ({ data }) => {
  //         resolve(data);
  //       })
  //       .catch((err) => {
  //         reject(err);
  //       });
  //   });
  // },
  [IMPORT_PRIVATE_USER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/companies/${param.id}/import-user`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  [IMPORT_PRIVATE_USER_PROMO](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/promo/import-user`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SINGLE_IMPORT_PRIVATE_USER](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.put(`api/companies/${param.id}/single-import-user`, param.data)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SINGLE_REMOVE_PRIVATE_USERS](context, param) {
    return new Promise((resolve, reject) => {
      ApiService.delete(`api/companies/${param.data.company_id || param.id}/private-user`, param)
        .then(async ({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  [SET_INQUIRY_VA](state, data) {
      if (!data.count) {
        data.rows = [];
        data.count = 0;
      } 
      state.inquiry_va = data;
  },
  [SET_STATUS_DIPAY](state, data) {
      if (!data.count) {
        data.rows = [];
        data.count = 0;
      } 
      state.status_dipay = data;
  },
  [SET_APPUSERS](state, data) {
    if (!data.count) {
      state.app_users = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = v.user_status ? 'Active' : 'Inactive';
      v.status_color = v.user_status ? 'tag-success' : 'tag-danger';

      v.mbayar_id_str = v.mbayar_id ? 'Registered' : 'Unregister';
      v.mbayar_id_color = v.mbayar_id ? 'tag-success' : 'tag-danger';
      // v.dipay_id_str = v.dipay_id ? 'Registered' : 'Unregister';
      // v.dipay_id_color = v.dipay_id ? 'tag-success' : 'tag-danger';

      v.private_status = v.is_private ? 'Private' : 'Public';
      if (!v.kyc_mbayar) v.kyc_mbayar = 'NOT_SUBMITED';
      // if (!v.dipay_kyc) v.dipay_kyc = 'NOT_SUBMITED';
      v.kyc_status_str = v.kyc_mbayar ? v.kyc_mbayar.replace(/_/g, ' ') : '-';
      v.kyc_status_color = !v.kyc_mbayar ? 'tag-default' : kyc_status[v.kyc_mbayar].color;
      v.private_status_color = v.is_private ? 'tag-primary' : 'tag-info';
      v.login_at_str = v.login_at ? moment(v.login_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.app_users = data;
  },

  [NEW_SET_APPUSERS](state, data) {
    if (!data.count) {
      state.new_app_users = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = v.user_status ? 'Active' : 'Inactive';
      v.status_color = v.user_status ? 'tag-success' : 'tag-danger';

      v.mbayar_id_str = v.mbayar_id ? 'Registered' : 'Unregister';
      v.mbayar_id_color = v.mbayar_id ? 'tag-success' : 'tag-danger';
      // v.dipay_id_str = v.dipay_id ? 'Registered' : 'Unregister';
      // v.dipay_id_color = v.dipay_id ? 'tag-success' : 'tag-danger';

      v.private_status = v.is_private ? 'Private' : 'Public';
      if (!v.kyc_mbayar) v.kyc_mbayar = 'NOT_SUBMITED';
      // if (!v.dipay_kyc) v.dipay_kyc = 'NOT_SUBMITED';
      v.kyc_status_str = v.kyc_mbayar ? v.kyc_mbayar.replace(/_/g, ' ') : '-';
      v.kyc_status_color = !v.kyc_mbayar ? 'tag-default' : kyc_status[v.kyc_mbayar].color;
      v.private_status_color = v.is_private ? 'tag-primary' : 'tag-info';
      v.login_at_str = v.login_at ? moment(v.login_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.new_app_users = data;
  },
  [SET_ANALYTIC_APPUSERS](state, data) {
    state.analytic_appuser = data;
  },


  [SET_POPUP_BANNER](state, data) {
    data.rows = data.rows.map((v) => {
      v.banner_status_str = status_banner[v.status].text;
      v.banner_status_color = status_banner[v.status].color;
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.updated_at_str = v.updated_at ? moment(v.updated_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      [v.target_page_text] = v.target_page;
      return v;
    });
    state.banners = data;
  },


  [SET_CATEGORIES](state, data) {
    if (!data.count) {
      state.get_categories = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.updated_at_str = v.updated_at ? moment(v.updated_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.get_categories = data;
  },
  [SET_REFERRAL_PROGRAM](state, data) {
    if (!data.count) {
      state.get_referral_program = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = status[v.status].text;
      v.status_color = status[v.status].color;
      v.referrer_amount_str = `Rp. ${v.referrer_amount.toLocaleString()}`;
      v.referred_amount_str = `Rp. ${v.referred_amount.toLocaleString()}`;
      return v;
    });
    state.get_referral_program = data;
  },
  [SET_REFERRAL_CLAIMS](state, data) {
    if (!data.count) {
      state.get_referral_claims = {
        count: 0,
        rows: [],
      };
      return;
    }
    data.rows = data.rows.map((v) => {
      v.status_str = referral_status[v.status].text;
      v.status_color = referral_status[v.status].color;
      v.claim_status_str = referral_claim_status[v.claim_status].text;
      v.claim_status_color = referral_claim_status[v.claim_status].color;
      v.created_at_str = v.created_at ? moment(v.created_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      v.updated_at_str = v.updated_at ? moment(v.updated_at).utcOffset(7).format('DD MMM YYYY, HH:mm') : '-';
      return v;
    });
    state.get_referral_claims = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
