<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title"> Chat</div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <!-- <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/> -->
          <!-- <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <!-- <el-select size="small" v-model="user.searchType" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <!-- <el-button type="primary" @click="searchHandlerGami" size="small"><font-awesome-icon icon="search"/> Search</el-button> -->
          <!-- <el-button type="primary"  v-b-modal.add @click="createChannel" size="small"><font-awesome-icon icon="search"/> Create Channel</el-button> -->
          <el-button type="primary" v-b-modal.add size="small"><font-awesome-icon icon="search"/> Create Channel</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="20%">Type</th>
              <th width="10%">Is Online</th>
              <th>Status </th>
              <th>Created at</th>

              <!-- <th>Action</th> -->
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in listcategory" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.type }}</td>
              <td class="text-nowrap text-muted">{{ item.is_online }}</td>
              <td>{{ item.status || '-' }}</td>
              <td>{{ item.created_at || '-' }}</td>
              <!-- <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="detailChannel(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Detail</a>
                </div>
              </td> -->
             </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
     </div>
    <div>
    </div>
     <b-modal size="xl" id="add" title="Create Channel" hide-footer v-model="modalCreateChannel" @hide="closeModalChannel">
        <b-form @submit.prevent="createChannel" @reset="closeModalChannel">
          <b-form-group label="Name">
            <el-input placeholder="Name" v-model="channel.name" />
          </b-form-group>
          <b-form-group label="Type">
            <el-input placeholder="Type" v-model="channel.type" />
          </b-form-group>
          <b-form-group label="Avatar">
            <el-input placeholder="Avatar" v-model="channel.avatar" />
          </b-form-group>
          <b-form-group label="Is Online">
            <el-select class="w-full" v-model="channel.is_online" placeholder="is online">
              <el-option v-for="category in online_option" :key="category.value" :value="category.value" :label="category.name">
              </el-option>
            </el-select>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="createChannel" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>

      <b-modal size="xl" id="edit" title="Update Channel" hide-footer v-model="modalUpdateChannel" @hide="closeModalChannel">
        <b-form @submit.prevent="createChannel" @reset="closeModalChannel">
          <b-form-group label="Title">
            <el-input placeholder="Title" v-model="selected_item.title" />
          </b-form-group>
          <b-form-group label="image_url">
            <el-input placeholder="image_url" v-model="selected_item.image_url" />
          </b-form-group>
          <b-form-group label="target_url">
            <el-input placeholder="target_url" v-model="selected_item.target_url" />
          </b-form-group>
          <b-form-group label="target_page">
            <el-input placeholder="target_page" v-model="selected_item.target_page" />
          </b-form-group>
          <b-form-group label="description">
            <el-input placeholder="description" v-model="selected_item.description" />
          </b-form-group>
          <b-form-group label="company_ids">
            <el-input placeholder="company_ids" v-model="selected_item.company_ids" />
          </b-form-group>
          <b-form-group label="user_ids">
            <el-input placeholder="user_ids" v-model="selected_item.user_ids" />
          </b-form-group>
          <b-form-group label="user_type">
            <el-select class="w-full" v-model="selected_item.user_type" placeholder="user_type">
              <el-option v-for="category in online_option" :key="category.value" :value="category.value" :label="category.name">
              </el-option>
            </el-select>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="createChannel" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { CREATE_CHANNEL, GET_CHANNEL } from '@/store/modules/channel';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import { GET_APPUSERS } from '@/store/modules/app_user';
export default {
  components: { Pagination },
  name: 'Channel',
  metaInfo: {
    title: 'Channel',
  },
  data() {
    return {
      user: {
        search_by: 'full_name',
        search_keyword: '',
        searchType: 1,
        searchUserTypeOptions: [
          { value: 1, text: 'Private' },
          { value: 0, text: 'Public' },
        ],
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      channel : {
        name: '',
        type: '',
        avatar: '',
        is_online: ''
      },
      // history_coin: [],
      isLoadingHistory: false,
      emptyStateImage,
      modalCreateChannel: false,
      closeModalChannel: false,
      modalUpdateChannel: false,
      showSearch: true,
      search_options: [
        { value: 'full_name', text: 'Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      online_option: [
        {
          value: false,
          label: 'False',
        },
        {
          value: true,
          label: 'True',
        },
      ],
      per_page: 20,
      size: 0,
      total_rows: 0,
      page: 1,
      list: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      listcategory: [],
      selected_item: {
        title: '',
        image_url: '',
        target_url: '',
        target_page: [],
        description: '',
        user_type: '',
        company_ids: [],
        user_ids: []
      },
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'full_name',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.listcategory.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    async detailChannel(item) {
      this.selected_item = item;
      this.modalCreateChannel = true;
    },
    async doUpdateChannel() {
      this.$confirm(
        'You will update the Event. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                title: this.selected_item.name, 
                image_url: this.selected_item.updated_by, 
                target_url: this.selected_item.description,
                content: this.selected_item.content,
                efective_date: this.selected_item.efective_date
              };
              const id = this.selected_transaction.id;
              await this.$store.dispatch(UPDATE_LEGAL, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'PDP updated',
                  });
                  this.onHide()
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async createChannel() {
      try {
        const requestBody = {
          name: this.channel.name, 
          type: this.channel.type,
          avatar: this.channel.avatar,
          is_online: this.channel.is_online,
        };
        const response = await this.$store.dispatch(CREATE_CHANNEL, { data: requestBody });
        this.channel.name = '';
        this.channel.type = '';
        this.channel.avatar = '';
        this.channel.is_online = '';
        this.data_channel = response;
        this.$message({
            title: 'Success',
            type: 'success',
            message: 'Channel created',
        });
        // this.closeModalChannel();
        this.onHide()
        this.getList();

      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    onHide() {
      this.modalCreateChannel = false;
      this.modalUpdateChannel = false;
      this.modalUpdateLStatus = false
    },
    capitalizeFirst(value) {
      return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchHandlerGami() {
      this.search_by = this.user.search_by;
      this.search_keyword = this.user.search_keyword;
      this.searchType = this.user.searchType
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_CHANNEL, {
      }).catch(() => {});
      const { rows, count } = this.$store.getters.channel;
      this.total_rows = count || 0;
      this.listcategory = rows.map((v) => {
        v.birth_place_date = (v.birth_place && v.birthday) ? [v.birth_place, moment(v.birthday).format('DD MMM YYYY')].join(', ') : '-';
        return v;
      });
      this.isLoading = false;
    },
    formatDate(timestamp) {
      return moment(timestamp).format('D MMMM YYYY, HH:mm A');
    },
    formatNumber(value) {
      return new Intl.NumberFormat('id-ID').format(value);
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}

.scrollable-card-body {
  max-height: 400px; 
  overflow-y: auto;
}

.el-timeline-item__timestamp {
  color: orange;
}

.custom-card {
  width: 500px;  
  height: auto; 
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

</style>
