<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card col-lg-12 mt-2">
      <div class="card-header flex justify-content-between">
        <div class="card-title"> List Legal</div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <!-- <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/> -->
          <!-- <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <!-- <el-select size="small" v-model="user.searchType" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
          </el-select> -->
          <!-- <el-button type="primary" @click="searchHandlerGami" size="small"><font-awesome-icon icon="search"/> Search</el-button> -->
          <!-- <el-button type="primary"  v-b-modal.add @click="createChannel" size="small"><font-awesome-icon icon="search"/> Create Channel</el-button> -->
          <el-button type="primary" v-b-modal.add size="small"><font-awesome-icon icon="search"/> Create PDP</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="20%">Category</th>
              <th width="10%">Updated By</th>
              <th>Status </th>
              <th>Action </th>
            </tr>
          </thead>
          <tbody v-loading="loading.table">
            <tr v-for="item in list_legal" :key="item._id">
              <td>{{ item.name }}</td>
              <td>{{ item.category }}</td>
              <td class="text-nowrap text-muted">{{ item.updated_by }}</td>
              <!-- <td>{{ item.created_at || '-' }}</td> -->
              <td>{{ item.status || '-' }}</td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="updatePdp(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Update PDP</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="updateStatus(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Update Status</a>
                </div>
              </td>
             </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="size"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
     </div>
    <div>
    </div>
    <b-modal size="lg" id="add" title="Create PDP" hide-footer v-model="modalCreateLegal" @hide="closeModalLegal">
        <b-form @submit.prevent="createLegal" @reset="closeModalLegal">
        <b-form-group label="Name">
            <el-input placeholder="Name" v-model="legal.name" />
        </b-form-group>
        <b-form-group label="Category">
            <el-select class="w-full" v-model="legal.category" placeholder="category">
            <el-option v-for="pdp in category_option" :key="pdp.id" :value="pdp.value" :label="pdp.name">
            </el-option>
            </el-select>
        </b-form-group>
        <b-form-group label="Updated By">
            <el-input placeholder="Updated By" v-model="legal.updated_by" />
        </b-form-group>
        <b-form-group label="Description">
            <el-input placeholder="description" v-model="legal.description" />
        </b-form-group>
        <b-form-group label="Content">
            <quill-editor v-model="legal.content"
                ref="content"
                :options="editorOption"
                style="height: 150px"
                >
            </quill-editor>
        </b-form-group><br><br><br><br>
        <div class="d-flex flex-row float-right">
            <el-button @click="createLegal" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
        </div>
        </b-form>
    </b-modal>
    <b-modal size="lg" id="edit" title="Update PDP" hide-footer v-model="modalUpdateLegal" @hide="closeModalLegal">
        <b-form @submit.prevent="doUpdatePdp" @reset="closeModalLegal">
        <b-form-group label="Name">
            <el-input placeholder="Name" v-model="selected_transaction.name" />
        </b-form-group>
        <b-form-group label="Updated By">
            <el-input placeholder="Updated By" v-model="selected_transaction.updated_by" />
        </b-form-group>
        <b-form-group label="Description">
            <el-input placeholder="description" v-model="selected_transaction.description" />
        </b-form-group>
        <b-form-group label="Content">
            <quill-editor v-model="selected_transaction.content"
                ref="content"
                :options="editorOption"
                style="height: 150px"
                >
            </quill-editor>
        </b-form-group><br><br><br><br>
        <div class="d-flex flex-row float-right">
            <el-button @click="doUpdatePdp" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
        </div>
        </b-form>
    </b-modal>
    <b-modal size="lg" id="edit" title="Update Status PDP" hide-footer v-model="modalUpdateLStatus" @hide="closeModalLegal">
        <b-form @submit.prevent="createLegal" @reset="closeModalLegal">
        <b-form-group label="Updated By">
            <el-input placeholder="Updated By" v-model="selected_transaction.updated_by" />
        </b-form-group>
        <b-form-group label="Status">
            <el-select class="w-full" v-model="selected_transaction.status" placeholder="status">
            <el-option v-for="pdp in status_option" :key="pdp.id" :value="pdp.value" :label="pdp.name">
            </el-option>
            </el-select>
        </b-form-group>
            <div class="d-flex flex-row float-right">
                <el-button @click="doUpdateStatus" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
                <el-button @click="onHide" size="small" class="mr-2">Cancel</el-button>
            </div>
        </b-form>
    </b-modal>
  </div>
</template>
<script>
import moment from 'moment';
import { upperFirst } from 'lodash';
import { CREATE_LEGAL, GET_LIST_LEGAL, UPDATE_STATUS_LEGAL, UPDATE_LEGAL } from '@/store/modules/legal';
import emptyStateImage from '@/assets/images/empty-state.png';
import Pagination from '../components/Pagination.vue';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
  components: { Pagination, quillEditor },
  name: 'Legal',
  metaInfo: {
    title: 'Legal',
  },
  data() {
    return {
    selected_transaction: [],
      user: {
        search_by: 'full_name',
        search_keyword: '',
        searchType: 1,
        searchUserTypeOptions: [
          { value: 1, text: 'Private' },
          { value: 0, text: 'Public' },
        ],
        search_options: [
          { value: 'full_name', text: 'Full Name' },
          { value: 'email', text: 'Email' },
          { value: 'phone_number', text: 'Phone Number' },
        ],
      },
      status_option: [
        {
          value: 'draft',
          label: 'Draft',
        },
        {
          value: 'review',
          label: 'Review',
        },
        {
          value: 'publish',
          label: 'Publish',
        },
      ],
      category_option: [
        {
          value: 'PDP',
          label: 'PDP',
        },
        {
          value: 'OTHER',
          label: 'OTHER',
        },
      ],
      legal : {
        name: '',
        description: '',
        updated_by: '',
        content: '',
        category: ''
      },
      legal_edit : {
        name: '',
        description: '',
        updated_by: '',
        content: '',
        category: ''
      },
      editorOption: {
        placeholder: '1. test legal Ewa  ...',
      },
      isLoadingHistory: false,
      emptyStateImage,
      modalCreateLegal: false,
      modalUpdateLegal: false,
      modalUpdateLStatus: false,
      closeModalLegal: false,
      showSearch: true,
      search_options: [
        { value: 'full_name', text: 'Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
      per_page: 20,
      size: 0,
      total_rows: 0,
      page: 1,
      list: [],
      data_pdp: [],
      isLoading: true,
      loading_detail: false,
      loader: null,
      search_keyword: '',
      search_by: '',
      list_legal: [],
      selected_item: {},
      loading: {
        summary_vendor: false,
      },
      search: {
        date: [
          moment().subtract(30, 'day').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
        search_by: 'full_name',
        search_keyword: '',
        start_date: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
        status_filter: '',
      },
      datePickerOptions: {
        disabledDate: (date) => moment(date).isAfter(moment(), 'days'),
      },
      
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list_legal.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    async updatePdp(item) {
      this.selected_transaction = item;
      console.log('Selected Transaction:', this.selected_transaction); 
      this.modalUpdateLegal = true;
    },
    async updateStatus(item) {
      this.selected_transaction = item;
      console.log('Selected Transaction:', this.selected_transaction); 
      this.modalUpdateLStatus = true;
      this.loading = true;
    },
    async doUpdatePdp() {
      this.$confirm(
        'You will update the Event. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                name: this.selected_transaction.name, 
                updated_by: this.selected_transaction.updated_by, 
                description: this.selected_transaction.description,
                content: this.selected_transaction.content,
                efective_date: this.selected_transaction.efective_date
              };
              const id = this.selected_transaction.id;
              await this.$store.dispatch(UPDATE_LEGAL, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'PDP updated',
                  });
                  this.onHide()
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async doUpdateStatus() {
      this.$confirm(
        'You will update the status. Make sure you have input the data correctly. Continue?',
        'Confirmation',
        {
          type: 'info',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              done();
              const requestBody = {
                status: this.selected_transaction.status, 
                updated_by: this.selected_transaction.updated_by, 
              };
              const id = this.selected_transaction.id;
              await this.$store.dispatch(UPDATE_STATUS_LEGAL, { id, data: requestBody })
                .then(() => {
                  this.$message({
                    title: 'Success',
                    type: 'success',
                    message: 'Status updated',
                  });
                  this.onHide();
                  this.getList();
                })
                .catch((err) => {
                  popupErrorMessages(err.response.data);
                });
              return;
            }
            done();
          },
        }
      ).catch(() => {});
    },
    async createLegal() {
      try {
        const requestBody = {
          name: this.legal.name, 
          category: this.legal.category,
          updated_by: this.legal.updated_by,
          description: this.legal.description,
          content: this.legal.content,
          efective_date : this.legal.efective_date
        };
        const response = await this.$store.dispatch(CREATE_LEGAL, { data: requestBody });
        this.legal.name = '';
        this.legal.category = '';
        this.legal.updated_by = '';
        this.legal.description = '';
        this.legal.content = '';
        this.legal.efective_date =''
        this.data_pdp = response;
        this.$message({
            title: 'Success',
            type: 'success',
            message: 'PDP created',
        });
        this.onHide()
        this.getList();

      } catch (err) {
        popupErrorMessages(err.response.data);
      }
    },
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.search.action = '';
      this.search.start_date = moment(this.search.date[0]).format('YYYY-MM-DD');
      this.search.end_date = moment(this.search.date[1]).format('YYYY-MM-DD');
      this.getList();
    },
    searchHandlerGami() {
      this.search_by = this.user.search_by;
      this.search_keyword = this.user.search_keyword;
      this.searchType = this.user.searchType
      this.getList();
    },
     onHide() {
      this.modalCreateLegal = false;
      this.modalUpdateLegal = false;
      this.modalUpdateLStatus = false
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch( GET_LIST_LEGAL, {
      }).catch(() => {});
      const { rows, count } = this.$store.getters.legal;
      this.total_rows = count || 0;
      this.list_legal = rows.map((v) => {
        return v;
      });
      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-range-separator {
  width: 10% !important;
}

.child1-sticky {
  position: sticky;
  right: 0px;
  width: 40px;
  background: #fff;
}

.child2-sticky {
  position: sticky;
  right: 69px;
  width: 255px;
  background: #fff;
}

.scrollable-card-body {
  max-height: 400px; 
  overflow-y: auto;
}

.el-timeline-item__timestamp {
  color: orange;
}

.custom-card {
  width: 500px;  
  height: auto; 
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

</style>
