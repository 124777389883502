import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'default', auth: false },
    component: () => import('../ui/pages/auth/Login.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Dashboard.vue'),
  },
  {
    path: '/uploadfile',
    name: 'Upload File',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/UploadFile.vue'),
  },
  {
    path: '/vouchers',
    name: 'Vouchers',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Vouchers.vue'),
  },
  {
    path: '/ppob',
    name: 'PPOB',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Ppob.vue'),
  },
  {
    path: '/vouchers/:action',
    name: 'Add Voucher',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/vouchers/AddVoucher.vue'),
  },
  {
    path: '/vouchers/:action/:id',
    name: 'Edit Voucher',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/vouchers/AddVoucher.vue'),
  },
  {
    path: '/merchants',
    name: 'Merchants',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Merchants.vue'),
  },
  // {
  //   path: '/my-benefit',
  //   name: 'Mybenefit',
  //   meta: { layout: 'dashboard', auth: true },
  //   component: () => import('@/ui/pages/Mybenefit.vue'),
  // },
  // {
  //   path: '/my-benefit/edit/:id',
  //   name: 'Edit Mybenefit',
  //   meta: { layout: 'dashboard', auth: true },
  //   component: () => import('@/ui/pages/mybenefit/EditMybenefit.vue'),
  // },
  {
    path: '/merchants-apps',
    name: 'Merchants App',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/MerchantApps.vue'),
  },
  {
    path: '/merchants-apps/all-po',
    name: 'Merchants App Pre Order',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/MerchantAppsPreOrder.vue'),
  },
  {
    path: '/merchants-apps/invoices',
    name: 'Merchants App Invoices',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/MerchantAppInvoice.vue'),
  },
  {
    path: '/merchants-apps/invoices/:id',
    name: 'Merchants App Invoice Detail',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/merchant_apps/InvoiceDetail.vue'),
  },
  {
    path: '/rewards',
    name: 'Rewards',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Rewards.vue'),
  },
  {
    path: '/academys',
    name: 'Academys',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Academys.vue'),
  },
  {
    path: '/missions',
    name: 'Missions',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Missions.vue'),
  },
  {
    path: '/missions/new',
    name: 'New Missions',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/MissionsAdd.vue'),
  },
  {
    path: '/users-app',
    name: 'User Apps',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/AppsUser.vue'),
  },
  {
    path: '/submission',
    name: 'Submission',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Submission.vue'),
  },
  {
    path: '/promo',
    name: 'Promo',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Promo.vue'),
  },
  {
    path: '/promo/:action',
    name: 'Create Promo',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/promo/CreatePromo.vue'),
    children: [
      {
        path: ':id',
        name: 'Edit Promo',
        meta: { layout: 'dashboard', auth: true },
        component: () => import('@/ui/pages/promo/CreatePromo.vue'),
      },
    ],
  },
  {
    path: '/show/:id/promo',
    name: 'Show Promo',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/promo/ShowPromo.vue'),
  },
  {
    path: '/transactions',
    name: 'Transactions',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Transactions.vue'),
  },
  {
    path: '/coin',
    name: 'Coin',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Coin.vue'),
  },
  {
    path: '/popup-banner',
    name: 'PopupBanner',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/PopupBanner.vue'),
  },
  {
    path: '/gamification/event',
    name: 'Gamification',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Gamification.vue'),
  },
  {
    path: '/gamification/transaction',
    name: 'Gamification Trx',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Transaction.vue'),
  },
  {
    path: '/NewRefund',
    name: 'Dipay Refund New',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/NewRefund.vue'),
  },
  {
    path: '/Refund',
    name: 'Dipay Refund',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Refund.vue'),
  },
  // {
  //   path: '/Role',
  //   name: 'Role',
  //   meta: { layout: 'dashboard', auth: true },
  //   component: () => import('@/ui/pages/Role.vue'),
  // },
  {
    path: '/referral',
    name: 'Referral',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Referral.vue'),
  },
  {
    path: '/referral/categories',
    name: 'Referral Categories',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/ReferralCategory.vue'),
  },
  {
    path: '/referral/claims',
    name: 'Referral Claims',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/ReferralClaim.vue'),
  },
  {
    path: '/finance/transactions',
    meta: { layout: 'dashboard', auth: true },
    children: [
      {
        path: '',
        name: 'Finance Transactions Report',
        meta: { layout: 'dashboard', auth: true },
        component: () => import('@/ui/pages/FinanceTransactions.vue'),
      },
      {
        path: ':vendor',
        name: 'Finance Transactions Report Vendor',
        meta: { layout: 'dashboard', auth: true },
        component: () => import('@/ui/pages/FinanceTransactions.vue'),
      },
    ],
    component: () => import('@/ui/pages/FinanceTransactions.vue'),
  },
  {
    path: '/channel',
    name: 'V-Chat',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Channel.vue'),
  },
  {
    path: '/legal',
    name: 'Legal',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Legal.vue'),
  },
  {
    path: '/app-setting',
    name: 'App Setting',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Transactions.vue'),
  },
  {
    path: '/internal-users',
    name: 'Admin',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/InternalUsers.vue'),
  },
  {
    path: '/broadcast',
    name: 'Broadcast',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Broadcast.vue'),
  },
  {
    path: '/disbursement',
    name: 'Disbursement',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Disbursement.vue'),
  },
  {
    path: '/disbursement/:id/detail',
    name: 'Disbursement Detail',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/disbursement/RecipientList.vue'),
  },
  {
    path: '/companies',
    name: 'Companies',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/Companies.vue'),
  },
  {
    path: '/companies/:id/private-user',
    name: 'Companies Private User',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/companies/PrivateUser.vue'),
  },
  {
    path: '/companies/:id/ewa',
    name: 'Companies Detail EWA',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/companies/DetailEwaCompany.vue'),
  },
  {
    path: '/companies/ewa',
    name: 'Companies EWA',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/companies/DetailEwaCompany.vue'),
  },
  {
    path: '/companies/mybenefit-request',
    name: 'MyBenefit Request',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('@/ui/pages/companies/MyBenefitRequest.vue'),
  },
  {
    path: '/app-contents/home',
    name: 'App Contents - Home',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/app_sections/HomeSections.vue'),
  },
  {
    path: '/refund-request',
    name: 'Refund Request',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/RefundRequest.vue'),
  },
  {
    path: '/refund-request/:id',
    name: 'Refund Request Detail',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/refund_request/RefundRequestDetail.vue'),
  },
  {
    path: '/earned-wage',
    name: 'EWA',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/Ewa.vue'),
  },
  {
    path: '/earned-wage/report',
    name: 'EWA Report',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/ewa/Report.vue'),
  },
  {
    path: '/earned-wage/invoices/:id',
    name: 'EWA Invoice',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/ewa/InvoiceList.vue'),
  },
  {
    path: '/earned-wage/invoices/:id/:invoice_id',
    name: 'EWA Invoice Detail',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/ewa/InvoiceDetail.vue'),
  },
  {
    path: '/earned-wage/:id/:status?',
    name: 'EWA Detail',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/ewa/DetailEwaCompany.vue'),
  },
  // {
  //   path: '/global-setting',
  //   beforeEnter() {
  //     window.location.href = 'http://settings.vdashboard.local.vntny.id/';
  //   },
  // },
  {
    path: '/deposit/history',
    name: 'History',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/deposit/Deposit.vue'),
  },
  {
    path: '/deposit/topup',
    name: 'Topup',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/deposit/Topup.vue'),
  },
  {
    path: '/deposit/adjustment',
    name: 'Adjustment',
    meta: {
      layout: 'dashboard',
      auth: true,
    },
    component: () => import('../ui/pages/deposit/Adjustment.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: { layout: 'default', auth: true },
    component: () => import('../ui/pages/error/404.vue'),
  },
  {
    path: '/403',
    name: 'Forbidden',
    meta: { layout: 'dashboard', auth: true },
    component: () => import('../ui/pages/error/403.vue'),
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isLogin = store.getters.isAuthenticated;
  const needLogin = to.meta.auth;
  const isErrorPage = to.meta.type && to.meta.type === 'error';

  if (needLogin && !isLogin && !isErrorPage) {
    router.push('/login');
  } else if (!needLogin && isLogin && !isErrorPage) {
    router.push('/dashboard');
  //   store.commit('setRedirectPath', to.path);
  //   next('/login');
  // } else if (!needLogin && isLogin && !isErrorPage && to.path === '/dashboard') {
  //   next();
  } else {
    if (!['/login', '/logout'].includes(to.path)) {
      const p = to.path.replace('/', '').split('/')[0];
      await store.dispatch('getMyRole', p);
    }
    next();
  }
});

export default router;
