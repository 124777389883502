<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="row row-deck row-cards mb-3">
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader">
                <span>Today New User</span>
              </div>
              <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_register < yesterday_register, 'text-green': today_register > yesterday_register }">
                <font-awesome-icon :icon="icon_today_register"/> {{ today_new_progress }}%
              </div>
            </div>
            <div class="h1 mb-3"><b>{{today_register}}</b></div>
            <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader">
                <span>Yesterday New User</span>
              </div>
              <!-- <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_register > yesterday_register, 'text-green': today_register < yesterday_register }">
                <font-awesome-icon :icon="icon_yesterday_register"/> {{
                  yesterday_register ? ((today_register/yesterday_register).toFixed(2) * 100).toFixed(0) : (today_register ? 100 : 0) }}%
              </div> -->
            </div>
            <div class="h1 mb-3"><b>{{yesterday_register}}</b></div>
            <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="register_chart.options" :series="register_chart.series"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader">
                <span>Today Login User</span>
              </div>
              <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_login < yesterday_login, 'text-green': today_login > yesterday_login }">
                <font-awesome-icon :icon="icon_today_login"/> {{ today_login_progress }}%
              </div>
            </div>
            <div class="h1 mb-3"><b>{{today_login}}</b></div>
            <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="login_chart.options" :series="login_chart.series"/>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="subheader">
                <span>Yesterday Login User</span>
              </div>
              <!-- <div class="ms-auto lh-1 subheader" :class="{ 'text-red': today_login > yesterday_login, 'text-green': today_login < yesterday_login }">
                <font-awesome-icon :icon="icon_yesterday_login"/> {{
                  yesterday_login ? ((today_login/yesterday_login).toFixed(2) * 100).toFixed(0) : (today_login ? 100 : 0) }}%
              </div> -->
            </div>
            <div class="h1 mb-3"><b>{{yesterday_login}}</b></div>
            <apexchart v-if="!isLoading" class="mb-2" height="50" id="apexpie" :options="login_chart.options" :series="login_chart.series"/>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header justify-content-between">
        <div class="card-title">
          <span class="mr-2">{{ $route.name }} List</span>
          <el-button @click="doShowModalImportUser" effect="dark" type="primary" class="ml-2" size="mini"><font-awesome-icon icon="user-plus"/>&nbsp;Import private user</el-button>
        </div>
        <div class="flex flex-row mb-2 pr-2 float-right">
          <b-input style="width: 200px" size="sm" type="text" v-model="user.search_keyword" placeholder="Input search keyword" class="mr-2"/>
          <el-select size="small" v-model="user.search_by" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in user.search_options" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <!-- new -->
          <el-select size="small" v-model="searchType" class="mr-2" style="width: 150px">
            <el-option v-for="(item, index) in searchUserTypeOptions" :key="index" :value="item.value" :label="item.text"/>
          </el-select>
          <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th width="20%"  style="cursor: pointer"> Name </th>
              <th width="20%">Email</th>
              <th width="10%">Phone Number</th>
              <th>Join Date</th>
              <th>Last Login Date</th>
              <th>Account Type</th>
              <th>Company Name</th>
              <!-- <th>Saldo Venteny</th> -->
              <!-- <th>Dipay STATUS test</th> -->
              <th>Status</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody v-loading="isLoading">
            <tr v-for="(item, i) in list" :key="i">
              <td>{{ item.full_name }}</td>
              <td>{{ item.email }}</td>
              <td class="text-nowrap text-muted">{{ item.phone_number }}</td>
              <td class="text-nowrap text-muted">{{ item.created_at_str }}</td>
              <td class="text-nowrap text-muted">{{ item.login_at_str }}</td>
              <td>
                <span class="tag" :class="item.private_status_color">{{ item.private_status }}</span>
              </td>
              <td>{{ item.company_name || '-' }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                  <a class="dropdown-item btn-sm cursor-pointer" @click="doShowModalGiveVoucher(item)"><font-awesome-icon icon="ticket-alt" class="text-primary"/>&nbsp; Embed Voucher</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="confirmBlockUnblockUser(item)"><font-awesome-icon icon="power-off" class="text-danger"/>&nbsp; Block/Unblock</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="showModalProfileUser = true; selected_user = item;"><font-awesome-icon icon="user"/>&nbsp; User Profile</a>
                  <a :class="{ disabled: item.is_private }" class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnSingleImport(item)"><font-awesome-icon icon="user-lock" class="text-brand"/>&nbsp; Set as Private User</a>
                  <a :class="{ disabled: !item.is_private }" class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnSingleRemove(item)"><font-awesome-icon icon="trash" class="text-brand"/>&nbsp; Remove from Private User</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="confirmUnlinkUser(item)"><font-awesome-icon icon="link" class="text-brand"/>&nbsp; Unbind</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="checkStatus(item)"><font-awesome-icon icon="question" class="text-brand"/>&nbsp; Check Dipay Status</a>
                  <a :class="{ disabled: !item.dipay_id }" class="dropdown-item btn-sm cursor-pointer" @click="handlerBtnInquriy(item)"><font-awesome-icon icon="user-lock" class="text-brand"/>&nbsp; Inquiry VA</a>
                  <a class="dropdown-item btn-sm cursor-pointer" @click="confirmResetPin(item)"><font-awesome-icon icon="user-lock" class="text-brand"/>&nbsp; Reset Pin</a>
                  <!-- <a class="dropdown-item btn-sm cursor-pointer" @click="detailCoinHistory(item)"><font-awesome-icon icon="dollar-sign" class="text-brand"/>&nbsp; History Coin</a> -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
      </div>
      <div class="card-footer d-flex justify-content-between">
        <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
        <div class="inline-table">
          <b-pagination
            class="table-cell"
            @change="pageChangeHandler"
            :per-page="per_page"
            :limit="5"
            :total-rows="total_rows"
            v-model="page"
          />
        </div>
      </div>
      <b-modal v-model="showModalGiveVoucher" :title="'Embed Voucher for ' + selected_user.full_name" hide-footer>
        <b-form @submit.prevent="confirmGiveVoucher" @reset="closeModalGiveVoucher">
          <b-form-group label="Voucher">
            <el-select :loading="isLoadingVoucher" v-model="v$.formGiveVoucher.voucher_id.$model" placeholder="Select or search voucher" class="w-full" filterable remote :remote-method="searchVoucher">
            <el-option
              v-for="item in list_voucher"
              :key="item.voucher_id"
              :label="item.voucher_name"
              :value="item.voucher_id">
            </el-option>
          </el-select>
            <b-form-invalid-feedback :state="!v$.formGiveVoucher.voucher_id.$invalid" v-if="v$.formGiveVoucher.voucher_id.$errors[0]">
              <span> {{ v$.formGiveVoucher.voucher_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Quantity">
            <b-input
              v-model="v$.formGiveVoucher.quantity.$model"
              placeholder="Voucher quantity. Min 1 and max 5"
              type="number"/>
            <b-form-invalid-feedback :state="!v$.formGiveVoucher.quantity.$invalid" v-if="v$.formGiveVoucher.quantity.$errors[0]">
              <span> {{ v$.formGiveVoucher.quantity.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- NEW FIELD -->
          <b-form-group label="Embed type">
            <el-select :loading="isLoadingVoucher" v-model="v$.formGiveVoucher.embed_type.$model" placeholder="Select Embed type" class="w-full" filterable remote :remote-method="searchVoucher">
              <el-option
                v-for="item in embed_type"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
            <b-form-invalid-feedback :state="!v$.formGiveVoucher.embed_type.$invalid" v-if="v$.formGiveVoucher.embed_type.$errors[0]">
              <span> {{ v$.formGiveVoucher.embed_type.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Tujuan embed">
            <b-form-textarea
              v-model="v$.formGiveVoucher.embed_purpose.$model"
              placeholder="Masukkan tujuan embed"
              type="text"/>
              <b-form-invalid-feedback :state="!v$.formGiveVoucher.embed_purpose.$invalid" v-if="v$.formGiveVoucher.embed_purpose.$errors[0]">
                <span> {{ v$.formGiveVoucher.embed_purpose.$errors[0].$message }}</span>
              </b-form-invalid-feedback>
          </b-form-group>
          <!-- NEW FIELD -->
          <div class="d-flex flex-row float-right">
            <el-button @click="confirmGiveVoucher" :loading="loading.give_voucher" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="closeModalGiveVoucher" size="small" class="mr-2">Cancel</el-button>
            <!-- <b-button class="mr-2" size="sm" @click="closeModalGiveVoucher" type="reset" variant="secondary">Cancel</b-button> -->
          </div>
        </b-form>
      </b-modal>
       <b-modal v-model="showStatusDipay" :title="'Dipay Status ' + selected_user.full_name" hide-footer>
        <b-form @reset="closeDipay">
          <b-form-group label="Account Name">
            <b-input type="text"  v-model="status_dipay.account_name" disabled/>
          </b-form-group>
          <b-form-group label="Kyc Status">
            <b-input type="text" v-model="status_dipay.kyc_status" disabled/>
          </b-form-group>
          <b-form-group label="Bind Status">
            <b-input type="text" v-model="status_dipay.bind_status" disabled/>
          </b-form-group>
          <b-form-group label="KYC Submission">
            <b-input type="text" v-model="status_dipay.verification_status" disabled/>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="closeDipay" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal size="lg" v-model="showModalImportUser" title="Import Private User" hide-footer>
        <b-form @submit.prevent="confirmImportUser" @reset="closeModalImportUser">
          <b-form-group label="Company">
            <el-select size="small" :loading="isLoadingCompany" v-model="v$.formImportPrivate.company_id.$model" placeholder="Select or search company" style="width: 70%" filterable remote :remote-method="searchCompany">
              <el-option
                v-for="item in companies.rows"
                :key="item.company_id"
                :label="item.company_name"
                :value="item.company_id">
              </el-option>
            </el-select>&nbsp;
            <el-button @click="doShowModalCreateCompany" type="primary" size="small" class="ml-2"><font-awesome-icon icon="building"/>&nbsp;Add Company</el-button>
            <b-form-invalid-feedback :state="!v$.formImportPrivate.company_id.$invalid" v-if="v$.formImportPrivate.company_id.$errors[0]">
              <span> {{ v$.formImportPrivate.company_id.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="File">
            <b-form-file
              v-model="v$.formImportPrivate.file.$model"
              :state="Boolean(v$.formImportPrivate.file.$model)"
              class="border-1 p-2"
              accept=".csv"
              ref="fileInput"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
            <b-form-invalid-feedback :state="false">
              <span>*only .csv file allowed</span>
            </b-form-invalid-feedback>
            <b-form-invalid-feedback :state="!v$.formImportPrivate.file.$invalid" v-if="v$.formImportPrivate.file.$errors[0]">
              <span> {{ v$.formImportPrivate.file.$errors[0].$message }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="d-flex flex-row float-right">
            <el-button @click="confirmImportUser" :loading="loading.import_user" class="mr-2 ml-2" size="small" type="primary">Submit</el-button>
            <el-button @click="closeModalImportUser" size="small" class="mr-2">Cancel</el-button>
          </div>
        </b-form>
      </b-modal>
      <b-modal v-model="showModalProfileUser" title="User Profile" hide-footer @onHide="showModalProfileUser = false">
        <el-descriptions class="margin-top mb-3" :column="1" border>
          <el-descriptions-item>
            <template slot="label" class="flex">
              <span class="flex">
                <calendar-icon/>&nbsp; Join Date
              </span>
            </template>
            {{ selected_user.created_at_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <calendar-time-icon/>&nbsp; Last Login Date
              </span>
            </template>
            {{ selected_user.login_at_str }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <writing-icon/>&nbsp; Full Name
              </span>
            </template>
            {{ selected_user.full_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <mail-icon/>&nbsp; Email
              </span>
            </template>
            {{ selected_user.email }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <device-mobile-icon/>&nbsp; Phone Number
              </span>
            </template>
            {{ selected_user.phone_number }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <cake-icon/>&nbsp; Birth Date
              </span>
            </template>
            {{ selected_user.birth_place_date || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <mailbox-icon/>&nbsp; Address
              </span>
            </template>
            {{ selected_user.address || '-' }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <span class="flex">
                <category-2-icon/>&nbsp; Account Type
              </span>
            </template>
            <span class="tag" :class="selected_user.private_status_color">{{ selected_user.private_status }}</span>
          </el-descriptions-item>
          <el-descriptions-item v-if="selected_user.is_private">
            <template slot="label">
              <span class="flex">
                <building-factory-2-icon/>&nbsp; Company Name
              </span>
            </template>
            {{ selected_user.company_name }}
          </el-descriptions-item>
        </el-descriptions>
      </b-modal>
      <b-modal size="xl" v-model="showHistoryCoin" :title="'Detail coin #' + selected_transaction.owned_by" hide-footer>
        <el-descriptions class="margin-top mb-3" title="Order Information" :column="2" border>   
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-date"></i>
              Name
            </template>
            {{ selected_transaction.full_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-document"></i>
              Email
            </template>
            {{ selected_transaction.email }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              Company Name
            </template>
            {{ selected_transaction.company_name }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-phone"></i>
              Phone Number
            </template>
            {{ selected_transaction.phone_number }}
          </el-descriptions-item>    
        </el-descriptions>
        <div>
          <div class="card" v-if="history_coin.length > 0">
            <div class="card-header border-0 justify-content-between">
              <div class="card-title">History Coin</div>
            </div>
            <div class="card-body">
              <el-timeline>
                <el-timeline-item v-for="item in history_coin" :timestamp="formatDate(item.timestamp)"
                  placement="top"
                  color="#ff8f15"
                  type="#ff8f15"
                  :key="item.title">
                  <el-card>
                    <p>{{ item.title }} {{ item.amount }} at {{ formatDate(item.timestamp) }}</p>
                  </el-card>
                </el-timeline-item>
              </el-timeline>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-header border-0 justify-content-between">
              <div class="card-title"> No history available for the selected coin. </div>
            </div>
          </div>
        </div>
      </b-modal>
      <create-company
        :is-shown="showModalCreateCompany"
        @afterSubmit="loadCompanies"
        @onClose="showModalCreateCompany = false" />
      <single-import-private
        :is-shown="showModalSingleImport"
        :selected-user="selected_user"
        @onHide="showModalSingleImport = false" />
      <single-remove-private
        :is-shown="showModalSingleRemove"
        :selected-user="selected_user"
        @onHide="showModalSingleRemove = false" />
      <inquiry-virtual-account
        :is-shown="showModalInquiry"
        :selected-user="selected_user"
        @onHide="showModalInquiry = false" />
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { orderBy } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required, minValue, maxValue, requiredIf } from '@vuelidate/validators';
import { GET_VOUCHERS } from '@/store/modules/vouchers';
import {
GET_APPUSERS, NEW_GET_APPUSERS, EMBED_VOUCHER, ANALYTIC_APPUSERS, BLOCK_UNBLOCK_APPUSER, IMPORT_PRIVATE_USER,UNLINK, RESET, INFO_STATUS_DIPAY
} from '@/store/modules/app_user';
import { GET_COIN_HISTORY } from '@/store/modules/coin';
import emptyStateImage from '@/assets/images/empty-state.png';
import popupErrorMessages from '@/library/popup-error-messages';
import { GET_COMPANIES, CREATE_COMPANIES } from '@/store/modules/companies';
import CreateCompany from './companies/CreateCompany.vue';
import SingleImportPrivate from './app_user/SingleImportPrivate.vue';
import SingleRemovePrivate from './app_user/SingleRemovePrivate.vue';
import InquiryVirtualAccount from './app_user/InquiryVirtualAccount.vue';
const isOther = (value) => value.includes('Other')
export default {
components: {
  CreateCompany,
  SingleImportPrivate,
  SingleRemovePrivate,
  InquiryVirtualAccount
},
name: 'AppsUser',
metaInfo: {
  title: 'User Apps',
},
setup() {
  return {
    v$: useVuelidate(),
  };
},
data() {
  return {
    history_coin: [],
    owned_by: 0,
    showHistoryCoin: false,
    selected_transaction: {},
    today: moment().format('YYYY-MM-DD'),
    yest: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    loading: {
      give_voucher: false,
      import_user: false,
      create_company: false,
    },
    embed_type: [
      {
        name: 'Birthday Gift',
        value: 'Birthday Gift',
      },
      {
        name: 'Refund',
        value: 'Refund',
      },
      {
        name: 'My Benefit - Monthly',
        value: 'My Benefit - Monthly',
      },
      {
        name: 'My Benefit - Activation',
        value: 'My Benefit - Activation',
      },
      {
        name: 'Give Away',
        value: 'Give Away',
      },
      {
        name: 'Other',
        value: 'Other',
      }
    ],
    status_dipay: {
        rows: [],
        count: 0,
    },
    searchType: 3,
    searchUserTypeOptions: [
      { value: 3, text: 'All' },
      { value: 1, text: 'Private' },
      { value: 0, text: 'Public' },
    ],
    showModalGiveVoucher: false,
    showStatusDipay: false,
    showModalImportUser: false,
    showModalCreateCompany: false,
    showModalSingleImport: false,
    showModalSingleRemove: false,
    showModalInquiry: false,
    showModalProfileUser: false,
    emptyStateImage,
    merchantList: [],
    merchant_id: '',
    filter_merchant_loading: false,
    per_page: 20,
    total_rows: 0,
    page: 1,
    list: [],
    sortKey: 'full_name', 
    sortOrder: 1 ,
    isSorted: true,
    isLoading: true,
    isLoadingVoucher: false,
    isLoadingCompany: false,
    loader: null,
    search_keyword: '',
    search_by: '',
    search_merchant_keyword: '',
    formGiveVoucher: {
      voucher_id: '',
      quantity: 1,
      embed_type:'',
      embed_purpose:''
    },
    formImportPrivate: {
      company_id: '',
      file: null,
    },
    formCompany: {
      company_name: '',
    },
    list_voucher: [],
    timeout: null,
    voucher: {
      search_by: 'voucher_name',
      search_keyword: '',
    },
    user: {
      search_by: 'full_name',
      search_keyword: '',
      search_options: [
        { value: 'full_name', text: 'Full Name' },
        { value: 'email', text: 'Email' },
        { value: 'phone_number', text: 'Phone Number' },
      ],
    },
    selected_user: {},
    analytic: {},
    today_register: 0,
    today_login: 0,
    yesterday_register: 0,
    yesterday_login: 0,
    register_chart: {
      options: {
        chart: {
          type: 'area',
          fontFamily: 'inherit',
          height: 40.0,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.16,
          type: 'solid',
        },
        stroke: {
          width: 2,
          lineCap: 'round',
          curve: 'smooth',
        },
        xaxis: {
          labels: {
            padding: 0,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          type: 'datetime',
          categories: [],
        },
        yaxis: {
          labels: {
            padding: 4,
          },
        },
        labels: [],
        legend: {
          show: false,
        },
      },
      series: [],
    },
    login_chart: {
      options: {
        chart: {
          type: 'area',
          fontFamily: 'inherit',
          height: 40.0,
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          opacity: 0.16,
          type: 'solid',
        },
        stroke: {
          width: 2,
          lineCap: 'round',
          curve: 'smooth',
        },
        xaxis: {
          labels: {
            padding: 0,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          type: 'datetime',
          categories: [],
        },
        yaxis: {
          labels: {
            padding: 4,
          },
        },
        labels: [],
        legend: {
          show: false,
        },
      },
      series: [],
    },
    companies: {
      rows: [],
      count: 0,
    },
    timeoutSearchCompany: null,
  };
},
computed: {
  showingFrom() {
    return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
  },
  showingUntil() {
    if (this.total_rows) {
      if (this.list.length < this.per_page) {
        return this.total_rows;
      }
      return this.page * this.per_page;
    }
    return 0;
  },
  icon_today_register() {
    if (this.today_register < this.yesterday_register) {
      return 'chevron-down';
    }
    if (this.today_register > this.yesterday_register) {
      return 'chevron-up';
    }
    return 'minus';
  },
  icon_yesterday_register() {
    if (this.today_register > this.yesterday_register) {
      return 'chevron-down';
    }
    if (this.today_register < this.yesterday_register) {
      return 'chevron-up';
    }
    return 'minus';
  },
  icon_today_login() {
    if (this.today_login < this.yesterday_login) {
      return 'chevron-down';
    }
    if (this.today_login > this.yesterday_login) {
      return 'chevron-up';
    }
    return 'minus';
  },
  icon_yesterday_login() {
    if (this.today_login > this.yesterday_login) {
      return 'chevron-down';
    }
    if (this.today_login < this.yesterday_login) {
      return 'chevron-up';
    }
    return 'minus';
  },
  today_new_progress() {
    if (this.yesterday_register > 0) {
      return (((this.today_register - this.yesterday_register) / this.yesterday_register).toFixed(3) * 100).toFixed(2);
    }
    if (this.yesterday_register === this.today_register) {
      return 0;
    }
    return 100;
  },
  today_login_progress() {
    if (this.yesterday_login > 0) {
      return (((this.today_login - this.yesterday_login) / this.yesterday_login).toFixed(3) * 100).toFixed(2);
    }
    if (this.yesterday_login === this.today_login) {
      return 0;
    }
    return 100;
  },
},
async mounted() {
  const loader = this.$loading.show();
  await this.getAnalytic();
  await this.getList();
  loader.hide();
},
methods: {
  pageChangeHandler(page) {
    this.page = page;
    this.getList();
  },
  searchVoucher(query) {
    this.timeout = setTimeout(() => {
      this.voucher.search_keyword = query;
      this.getListVoucher();
    }, 1000);
  },
  searchHandler() {
    this.search_by = this.user.search_by;
    this.search_keyword = this.user.search_keyword;
    this.getList();
  },
  async getListVoucher() {
    this.isLoadingVoucher = true;
    await this.$store.dispatch(GET_VOUCHERS, {
      search_by: this.voucher.search_by,
      search_keyword: this.voucher.search_keyword,
    }).catch(() => {});
    const { rows } = this.$store.getters.vouchers;
    this.list_voucher = rows;
    this.isLoadingVoucher = false;
  },
  async getList() {
    this.isLoading = true;
    // await this.$store.dispatch(GET_APPUSERS, {
    await this.$store.dispatch(NEW_GET_APPUSERS, {
      page: this.page,
      per_page: this.per_page,
      search_by: this.search_by,
      search_keyword: this.search_keyword,
      // is_private: 1
      is_private: this.searchType,
    }).catch(() => {});
    const { rows, count } = this.$store.getters.new_app_users;
    this.total_rows = count || 0;
    this.list = rows.map((v) => {
      v.birth_place_date = (v.birth_place && v.birthday) ? [v.birth_place, moment(v.birthday).format('DD MMM YYYY')].join(', ') : '-';
      return v;
    });
    this.isLoading = false;
  },
  async getAnalytic() {
    this.isLoading = true;
    await this.$store.dispatch(ANALYTIC_APPUSERS, {
      start_date: moment().startOf('year').format('YYYY-MM-DD'),
      end_date: moment().endOf('month').format('YYYY-MM-DD'),
    }).catch(() => {});
    this.analytic = this.$store.getters.analytic_appuser;
    this.analytic.login_daily = this.analytic.login_daily.filter((v) => v && v.login_date);

    this.today_register = this.analytic.register_daily.find((v) => v.register_date === this.today)?.count || 0;
    this.today_login = this.analytic.login_daily.find((v) => v.login_date === this.today)?.count || 0;
    this.yesterday_register = this.analytic.register_daily.find((v) => v.register_date === this.yest)?.count || 0;
    this.yesterday_login = this.analytic.login_daily.find((v) => v.login_date === this.yest)?.count || 0;
    const obj = orderBy(this.analytic.register_daily, 'register_date').reverse().splice(0, 30).reverse();
    this.register_chart.series = [{
      name: 'Daily New User',
      data: obj.map((v) => v.count),
    }];
    this.register_chart.options.labels = obj.map((v) => v.register_date);
    this.register_chart.options.xaxis.categories = obj.map((v) => v.register_date);

    let obj_login = orderBy(this.analytic.login_daily, 'login_date');
    obj_login = obj_login.reverse().splice(0, 30).reverse();

    this.login_chart.series = [{
      name: 'Daily Login',
      data: obj_login.map((v) => v.count),
    }];
    this.login_chart.options.labels = obj_login.map((v) => v.login_date);
    this.login_chart.options.xaxis.categories = obj_login.map((v) => v.login_date);

    this.isLoading = false;
  },
  doShowModalGiveVoucher(item) {
    this.selected_user = item;
    this.v$.formGiveVoucher.$touch();
    this.getListVoucher();
    this.showModalGiveVoucher = true;
  },
  doShowModalDipay(item) {
    this.selected_user = item;
    this.showStatusDipay = true
  },
  closeModalGiveVoucher() {
    this.showModalGiveVoucher = false;
    this.formGiveVoucher = {
      voucher_id: '',
      quantity: 1,
      embed_type:'',
      embed_purpose:''
    };
  },
  closeDipay() {
    this.showStatusDipay = false
  },
  confirmGiveVoucher() {
    this.v$.formGiveVoucher.$touch();
    if (this.v$.formGiveVoucher.$error) return;
    this.$confirm(`Are you sure want to embed voucher for <b>${this.selected_user.full_name}</b>?`, 'Confirmation', {
      dangerouslyUseHTMLString: true,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          this.doGiveVoucher();
        }
        done();
      },
    }).catch(() => {});
  },
  async doGiveVoucher() {
    this.loading.give_voucher = true;
    await this.$store.dispatch(EMBED_VOUCHER, {
      id: this.selected_user.owned_by,
      data: {
        voucher_id: this.formGiveVoucher.voucher_id,
        quantity: parseInt(this.formGiveVoucher.quantity, 0),
        embed_type: this.formGiveVoucher.embed_type,
        embed_purpose: this.formGiveVoucher.embed_purpose,
      },
    }).then(() => {
      this.showModalGiveVoucher = false;
      this.$message({
        title: 'Success',
        type: 'success',
        message: 'Embed voucher success',
      });
    }).catch((err) => {
      popupErrorMessages(err.response.data);
    });
    this.loading.give_voucher = false;
  },
  confirmBlockUnblockUser(item) {
    let msg = 'Are you sure want to unblock this user?';
    if (item.user_status === 1) {
      msg = 'Are you sure want to block this user?';
    }
    this.$confirm(msg, 'Confirmation', {
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          try {
            await this.$store.dispatch(BLOCK_UNBLOCK_APPUSER, {
              id: item.owned_by,
              action: item.user_status === 1 ? 'block' : 'unblock',
            });
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'Successfully update user data',
            });
          } catch (error) {
            this.$message({
              title: 'Failed',
              type: 'error',
              message: 'Something went wrong',
            });
          }
          this.getList();
          instance.confirmButtonLoading = false;
          done();
          return;
        }
        done();
      },
    }).catch(() => {});
  },
  confirmUnlinkUser(item) {
    let msg = 'Are you sure want to unbind this user?';
    this.$confirm(msg, 'Confirmation', {
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          try {
            await this.$store.dispatch(UNLINK, {
              owned_by: item.owned_by,
            });
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'User successfully unbound',
            });
          } catch (error) {
            this.$message({
              title: 'Failed',
              type: 'error',
              message: 'Something went wrong',
            });
          }
          this.getList();
          instance.confirmButtonLoading = false;
          done();
          return;
        }
        done();
      },
    }).catch(() => {});
  },
  async checkStatus(item) {
    try {
      this.selected_user = item;
      await this.$store.dispatch(INFO_STATUS_DIPAY, {
        owned_by: item.owned_by,
      });
      this.showStatusDipay = true;
      this.status_dipay = this.$store.getters.status_dipay;
    } catch (error) {
      const errorMessage = error.response?.data?.error?.title || 'Something went wrong';
      this.$message({
        title: 'Failed',
        type: 'error',
        message: errorMessage,
      });
    }
  },
  confirmResetPin(item) {
    let msg = 'Are you sure want to reset pin this user?';
    this.$confirm(msg, 'Confirmation', {
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          try {
            await this.$store.dispatch(RESET, {
              owned_by: item.owned_by,
            });
            this.$message({
              title: 'Success',
              type: 'success',
              message: 'Successfully reset pin',
            });
          } catch (error) {
            this.$message({
              title: 'Failed',
              type: 'error',
              message: 'Something went wrong',
            });
          }
          this.getList();
          instance.confirmButtonLoading = false;
          done();
          return;
        }
        done();
      },
    }).catch(() => {});
  },
  async loadCompanies({ search_keyword = '' } = {}) {
    this.isLoadingCompany = true;
    await this.$store.dispatch(GET_COMPANIES, {
      search_keyword,
    });
    this.isLoadingCompany = false;
    this.companies = this.$store.getters.companies;
  },
  searchCompany(value) {
    clearTimeout(this.timeoutSearchCompany);
    this.timeoutSearchCompany = setTimeout(() => {
      this.loadCompanies({
        search_keyword: value,
      });
    }, 1000);
  },
  doShowModalCreateCompany() {
    this.showModalCreateCompany = true;
    this.v$.formCompany.$touch();
  },
  closeModalCreateCompany() {
    this.showModalCreateCompany = false;
  },
  doShowModalImportUser() {
    this.showModalImportUser = true;
    this.loadCompanies();
    this.v$.formImportPrivate.$touch();
  },
  closeModalImportUser() {
    this.showModalImportUser = false;
  },
  async confirmImportUser() {
    this.v$.formImportPrivate.$touch();
    if (this.v$.formImportPrivate.$error) return;
    const data = new FormData();
    data.append('file', this.formImportPrivate.file);
    this.loading.import_user = true;
    await this.$store.dispatch(IMPORT_PRIVATE_USER, {
      id: this.formImportPrivate.company_id,
      data,
    }).then(() => {
      this.formImportPrivate = {
        company_id: '',
        file: null,
      };
      this.$message({
        title: 'Success',
        type: 'success',
        message: 'Import private user completed.',
      });
      this.closeModalImportUser();
      this.getList();
    }).catch((err) => {
      popupErrorMessages(err.response.data);
    });
    this.loading.import_user = false;
  },
  async confirmCreateCompany() {
    this.v$.formCompany.$touch();
    if (this.v$.formCompany.$error) return;

    this.loading.create_company = true;
    await this.$store.dispatch(CREATE_COMPANIES, this.formCompany)
      .then(() => {
        this.formCompany = {
          company_name: '',
        };
        this.$message({
          title: 'Success',
          type: 'success',
          message: 'Successfully add new company.',
        });
        this.closeModalCreateCompany();
        this.loadCompanies();
      }).catch((err) => {
        popupErrorMessages(err.response.data);
      });
    this.loading.create_company = false;
  },
  handlerBtnSingleImport(item) {
    this.selected_user = item;
    this.showModalSingleImport = true;
  },
  handlerBtnSingleRemove(item) {
    this.selected_user = item;
    this.showModalSingleRemove = true;
  },
  handlerBtnInquriy(item) {
    const fullName = item.full_name;
    const phoneNumber = item.phone_number;
    this.selected_user = item;
    this.selectedUserFullName = fullName;
    this.selectedUserPhoneNumber = phoneNumber;
    this.showModalInquiry = true;
  },
  async detailCoinHistory(item) {
    this.selected_transaction = item;
    this.showHistoryCoin = true;
    this.loading = true;
    try {
      const response = await this.$store.dispatch(GET_COIN_HISTORY, { owned_by: this.selected_transaction.owned_by, page: this.page });
      if (response.data && response.data.rows && response.data.rows.length > 0) {
        const historyData = response.data.rows.map(entry => ({
          title: entry.title,
          description: entry.description,
          type: entry.type,
          amount: entry.amount,
          timestamp: entry.timestamp
        }));
        this.history_coin = historyData; 
        if (this.history_coin.length === 0) {
            this.$message.info('No history available for the selected coin.');
        }
      } else {
          this.history_coin = [];
          this.$message.info('No history available for the selected coin.');
      }
    } catch (error) {
        this.$message.error('Error fetching coin history');
    } finally {
        this.loading = false;
    }
  },
  viewHistoryCoin(item) {
    this.selected_transaction = item;
    this.showHistoryCoin = true;
  },
  formatDate(timestamp) {
    return moment(timestamp).format('D MMMM YYYY, HH:mm A');
  },
},
validations() {
  return {
    formGiveVoucher: {
      voucher_id: {
        required,
      },
      quantity: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(5),
      },
      embed_type: {
        required,
      },
      embed_purpose: {
        required,
      },
    },
    formImportPrivate: {
      company_id: { required },
      file: { required },
    },
    formCompany: {
      company_name: { required },
    },
  };
},
};
</script>
